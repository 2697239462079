import { io } from 'socket.io-client';



/*** SignIn ***/
export const SignIn_START = "SignIn_START";
export const SignIn_SUCCESS = "SignIn_SUCCESS";
export const SignIn_FAIL = "SignIn_FAIL";


export const ResetPassword_START = "ResetPassword_START";
export const ResetPassword_SUCCESS = "ResetPassword_SUCCESS";
export const ResetPassword_FAIL = "ResetPassword_FAIL";

export const ForgotPassword_START = "ForgotPassword_START";
export const ForgotPassword_SUCCESS = "ForgotPassword_SUCCESS";
export const ForgotPassword_FAIL = "ForgotPassword_FAIL";


/*** Country ***/

export const SelectCountry_START = "SelectCountry_START";
export const SelectCountry_SUCCESS = "SelectCountry_SUCCESS";
export const SelectCountry_FAIL = "SelectCountry_FAIL";

export const GetAllSelectedCountries_START = "GetAllSelectedCountries_START";
export const GetAllSelectedCountries_SUCCESS = "GetAllSelectedCountries_SUCCESS";
export const GetAllSelectedCountries_FAIL = "GetAllSelectedCountries_FAIL";

export const GetAllStatesByCountryId_START = "GetAllStatesByCountryId_START";
export const GetAllStatesByCountryId_SUCCESS = "GetAllStatesByCountryId_SUCCESS";
export const GetAllStatesByCountryId_FAIL = "GetAllStatesByCountryId_FAIL";

export const CreateNewState_START = "CreateNewState_START";
export const CreateNewState_SUCCESS = "CreateNewState_SUCCESS";
export const CreateNewState_FAIL = "CreateNewState_FAIL";

export const GetAllCitiesByStateId_START = "GetAllCitiesByStateId_START";
export const GetAllCitiesByStateId_SUCCESS = "GetAllCitiesByStateId_SUCCESS";
export const GetAllCitiesByStateId_FAIL = "GetAllCitiesByStateId_FAIL";

export const CreateNewCity_START = "CreateNewCity_START";
export const CreateNewCity_SUCCESS = "CreateNewCity_SUCCESS";
export const CreateNewCity_FAIL = "CreateNewCity_FAIL";

export const DeactivateCity_START = "DeactivateCity_START";
export const DeactivateCity_SUCCESS = "DeactivateCity_SUCCESS";
export const DeactivateCity_FAIL = "DeactivateCity_FAIL";

export const DeactivateState_START = "DeactivateState_START";
export const DeactivateState_SUCCESS = "DeactivateState_SUCCESS";
export const DeactivateState_FAIL = "DeactivateState_FAIL";


/*** Superadmin School ***/

export const CreateSchool_START = "CreateSchool_START";
export const CreateSchool_SUCCESS = "CreateSchool_SUCCESS";
export const CreateSchool_FAIL = "CreateSchool_FAIL";

export const GetAllSchool_START = "GetAllSchool_START";
export const GetAllSchool_SUCCESS = "GetAllSchool_SUCCESS";
export const GetAllSchool_FAIL = "GetAllSchool_FAIL";


export const GetSchoolById_START = "GetSchoolById_START";
export const GetSchoolById_SUCCESS = "GetSchoolById_SUCCESS";
export const GetSchoolById_FAIL = "GetSchoolById_FAIL";


export const UpdateSchool_START = "UpdateSchool_START";
export const UpdateSchool_SUCCESS = "UpdateSchool_SUCCESS";
export const UpdateSchool_FAIL = "UpdateSchool_FAIL";


export const DeactivateSchool_START = "DeactivateSchool_START";
export const DeactivateSchool_SUCCESS = "DeactivateSchool_SUCCESS";
export const DeactivateSchool_FAIL = "DeactivateSchool_FAIL";


export const DeleteSchoolAndDatabase_START = "DeleteSchoolAndDatabase_START";
export const DeleteSchoolAndDatabase_SUCCESS = "DeleteSchoolAndDatabase_SUCCESS";
export const DeleteSchoolAndDatabase_FAIL = "DeleteSchoolAndDatabase_FAIL";

export const UpdateSchoolUser_START = "UpdateSchoolUser_START";
export const UpdateSchoolUser_SUCCESS = "UpdateSchoolUser_SUCCESS";
export const UpdateSchoolUser_FAIL = "UpdateSchoolUser_FAIL";


export const LoginAsSchoolAdmin_START = "LoginAsSchoolAdmin_START";
export const LoginAsSchoolAdmin_SUCCESS = "LoginAsSchoolAdmin_SUCCESS";
export const LoginAsSchoolAdmin_FAIL = "LoginAsSchoolAdmin_FAIL";

/*** Superadmin Dashboard ***/

export const GetAllDashboardSchools_START = "GetAllDashboardSchools_START";
export const GetAllDashboardSchools_SUCCESS = "GetAllDashboardSchools_SUCCESS";
export const GetAllDashboardSchools_FAIL = "GetAllDashboardSchools_FAIL";



/*** TopBar MIA notification to manager ***/

export const GetStuLocNotificationsbyUserId_START = "GetStuLocNotificationsbyUserId_START";
export const GetStuLocNotificationsbyUserId_SUCCESS = "GetStuLocNotificationsbyUserId_SUCCESS";
export const GetStuLocNotificationsbyUserId_FAIL = "GetStuLocNotificationsbyUserId_FAIL";

export const DeleteStuLocNotificationsbyUserId_START = "DeleteStuLocNotificationsbyUserId_START";
export const DeleteStuLocNotificationsbyUserId_SUCCESS = "DeleteStuLocNotificationsbyUserId_SUCCESS";
export const DeleteStuLocNotificationsbyUserId_FAIL = "DeleteStuLocNotificationsbyUserId_FAIL";

export const UpdateStuLocNotificationStatus_START = "UpdateStuLocNotificationStatus_START";
export const UpdateStuLocNotificationStatus_SUCCESS = "UpdateStuLocNotificationStatus_SUCCESS";
export const UpdateStuLocNotificationStatus_FAIL = "UpdateStuLocNotificationStatus_FAIL";




/*** School Admin Dormitory ***/

export const CreateDormitories_START = "CreateDormitories_START";
export const CreateDormitories_SUCCESS = "CreateDormitories_SUCCESS";
export const CreateDormitories_FAIL = "CreateDormitories_FAIL";

export const GetAllDormitory_START = "GetAllDormitory_START";
export const GetAllDormitory_SUCCESS = "GetAllDormitory_SUCCESS";
export const GetAllDormitory_FAIL = "GetAllDormitory_FAIL";

export const UpdateDormitory_START = "UpdateDormitory_START";
export const UpdateDormitory_SUCCESS = "UpdateDormitory_SUCCESS";
export const UpdateDormitory_FAIL = "UpdateDormitory_FAIL";

export const GetDormitoryDetailsById_START = "GetDormitoryDetailsById_START";
export const GetDormitoryDetailsById_SUCCESS = "GetDormitoryDetailsById_SUCCESS";
export const GetDormitoryDetailsById_FAIL = "GetDormitoryDetailsById_FAIL";


export const UpdateDormitoryStatus_START = "UpdateDormitoryStatus_START";
export const UpdateDormitoryStatus_SUCCESS = "UpdateDormitoryStatus_SUCCESS";
export const UpdateDormitoryStatus_FAIL = "UpdateDormitoryStatus_FAIL";


export const DeleteDormitory_START = "DeleteDormitory_START";
export const DeleteDormitory_SUCCESS = "DeleteDormitory_SUCCESS";
export const DeleteDormitory_FAIL = "DeleteDormitory_FAIL";

export const GetAllDormitoryListForSnapshot_START = "GetAllDormitoryListForSnapshot_START";
export const GetAllDormitoryListForSnapshot_SUCCESS = "GetAllDormitoryListForSnapshot_SUCCESS";
export const GetAllDormitoryListForSnapshot_FAIL = "GetAllDormitoryListForSnapshot_FAIL";



/*** School Admin Manager ***/


export const GetAllManagersBySchoolId_START = "GetAllManagersBySchoolId_START";
export const GetAllManagersBySchoolId_SUCCESS = "GetAllManagersBySchoolId_SUCCESS";
export const GetAllManagersBySchoolId_FAIL = "GetAllManagersBySchoolId_FAIL";


export const GetSchoolManagerById_START = "GetSchoolManagerById_START";
export const GetSchoolManagerById_SUCCESS = "GetSchoolManagerById_SUCCESS";
export const GetSchoolManagerById_FAIL = "GetSchoolManagerById_FAIL";


export const UpdateSchooManager_START = "UpdateSchooManager_START";
export const UpdateSchooManager_SUCCESS = "UpdateSchooManager_SUCCESS";
export const UpdateSchooManager_FAIL = "UpdateSchooManager_FAIL";


export const CreateSchoolManager_START = "CreateSchoolManager_START";
export const CreateSchoolManager_SUCCESS = "CreateSchoolManager_SUCCESS";
export const CreateSchoolManager_FAIL = "CreateSchoolManager_FAIL";


export const ArchiveOrUnarchiveManager_START = "ArchiveOrUnarchiveManager_START";
export const ArchiveOrUnarchiveManager_SUCCESS = "ArchiveOrUnarchiveManager_SUCCESS";
export const ArchiveOrUnarchiveManager_FAIL = "ArchiveOrUnarchiveManager_FAIL";


export const ExportManagersCsv_START = "ExportManagersCsv_START";
export const ExportManagersCsv_SUCCESS = "ExportManagersCsv_SUCCESS";
export const ExportManagersCsv_FAIL = "ExportManagersCsv_FAIL";


export const GetAllManagersListBySchoolId_START = "GetAllManagersListBySchoolId_START";
export const GetAllManagersListBySchoolId_SUCCESS = "GetAllManagersListBySchoolId_SUCCESS";
export const GetAllManagersListBySchoolId_FAIL = "GetAllManagersListBySchoolId_FAIL";


export const UpdateManagerPassword_START = "UpdateManagerPassword_START";
export const UpdateManagerPassword_SUCCESS = "UpdateManagerPassword_SUCCESS";
export const UpdateManagerPassword_FAIL = "UpdateManagerPassword_FAIL";


export const UpdateUserProfile_START = "UpdateUserProfile_START";
export const UpdateUserProfile_SUCCESS = "UpdateUserProfile_SUCCESS";
export const UpdateUserProfile_FAIL = "UpdateUserProfile_FAIL";


/*** School Admin Class ***/

export const CreateClass_START = "CreateClass_START";
export const CreateClass_SUCCESS = "CreateClass_SUCCESS";
export const CreateClass_FAIL = "CreateClass_FAIL";

export const GetAllClassesDetails_START = "GetAllClassesDetails_START";
export const GetAllClassesDetails_SUCCESS = "GetAllClassesDetails_SUCCESS";
export const GetAllClassesDetails_FAIL = "GetAllClassesDetails_FAIL";


export const UpdateClassStatus_START = "UpdateClassStatus_START";
export const UpdateClassStatus_SUCCESS = "UpdateClassStatus_SUCCESS";
export const UpdateClassStatus_FAIL = "UpdateClassStatus_FAIL";


export const DeleteClass_START = "DeleteClass_START";
export const DeleteClass_SUCCESS = "DeleteClass_SUCCESS";
export const DeleteClass_FAIL = "DeleteClass_FAIL";

export const GetClassDetailsById_START = "GetClassDetailsById_START";
export const GetClassDetailsById_SUCCESS = "GetClassDetailsById_SUCCESS";
export const GetClassDetailsById_FAIL = "GetClassDetailsById_FAIL";

export const UpdateClass_START = "UpdateClass_START";
export const UpdateClass_SUCCESS = "UpdateClass_SUCCESS";
export const UpdateClass_FAIL = "UpdateClass_FAIL";

export const GetAllClassesList_START = "GetAllClassesList_START";
export const GetAllClassesList_SUCCESS = "GetAllClassesList_SUCCESS";
export const GetAllClassesList_FAIL = "GetAllClassesList_FAIL";

export const GetAllClassStudentsCountForChart_START = "GetAllClassStudentsCountForChart_START";
export const GetAllClassStudentsCountForChart_SUCCESS = "GetAllClassStudentsCountForChart_SUCCESS";
export const GetAllClassStudentsCountForChart_FAIL = "GetAllClassStudentsCountForChart_FAIL";


/*** School Admin Student ***/

export const CreateStudentAndParents_START = "CreateStudentAndParents_START";
export const CreateStudentAndParents_SUCCESS = "CreateStudentAndParents_SUCCESS";
export const CreateStudentAndParents_FAIL = "CreateStudentAndParents_FAIL";

export const GetAllDormitoryStudent_START = "GetAllDormitoryStudent_START";
export const GetAllDormitoryStudent_SUCCESS = "GetAllDormitoryStudent_SUCCESS";
export const GetAllDormitoryStudent_FAIL = "GetAllDormitoryStudent_FAIL";

export const GetAllManagersBySchoolIdStudent_START = "GetAllManagersBySchoolIdStudent_START";
export const GetAllManagersBySchoolIdStudent_SUCCESS = "GetAllManagersBySchoolIdStudent_SUCCESS";
export const GetAllManagersBySchoolIdStudent_FAIL = "GetAllManagersBySchoolIdStudent_FAIL";

export const GetAllStudents_START = "GetAllStudents_START";
export const GetAllStudents_SUCCESS = "GetAllStudents_SUCCESS";
export const GetAllStudents_FAIL = "GetAllStudents_FAIL";



export const UpdateStudentStatus_START = "UpdateStudentStatus_START";
export const UpdateStudentStatus_SUCCESS = "UpdateStudentStatus_SUCCESS";
export const UpdateStudentStatus_FAIL = "UpdateStudentStatus_FAIL";


export const GetStudentAndParentById_START = "GetStudentAndParentById_START";
export const GetStudentAndParentById_SUCCESS = "GetStudentAndParentById_SUCCESS";
export const GetStudentAndParentById_FAIL = "GetStudentAndParentById_FAIL";


export const UpdateStudentPersonal_START = "UpdateStudentPersonal_START";
export const UpdateStudentPersonal_SUCCESS = "UpdateStudentPersonal_SUCCESS";
export const UpdateStudentPersonal_FAIL = "UpdateStudentPersonal_FAIL";


export const UpdateParentPersonal_START = "UpdateParentPersonal_START";
export const UpdateParentPersonal_SUCCESS = "UpdateParentPersonal_SUCCESS";
export const UpdateParentPersonal_FAIL = "UpdateParentPersonal_FAIL";


export const GetAllClassesDetailsStudent_START = "GetAllClassesDetailsStudent_START";
export const GetAllClassesDetailsStudent_SUCCESS = "GetAllClassesDetailsStudent_SUCCESS";
export const GetAllClassesDetailsStudent_FAIL = "GetAllClassesDetailsStudent_FAIL";

export const ExportStudentsCsv_START = "ExportStudentsCsv_START";
export const ExportStudentsCsv_SUCCESS = "ExportStudentsCsv_SUCCESS";
export const ExportStudentsCsv_FAIL = "ExportStudentsCsv_FAIL";


export const ExportStudentsPdf_START = "ExportStudentsPdf_START";
export const ExportStudentsPdf_SUCCESS = "ExportStudentsPdf_SUCCESS";
export const ExportStudentsPdf_FAIL = "ExportStudentsPdf_FAIL";

export const GetParentDetailsByFatherEmail_START = "GetParentDetailsByFatherEmail_START";
export const GetParentDetailsByFatherEmail_SUCCESS = "GetParentDetailsByFatherEmail_SUCCESS";
export const GetParentDetailsByFatherEmail_FAIL = "GetParentDetailsByFatherEmail_FAIL";

export const GetParentDetailsByMotherEmail_START = "GetParentDetailsByMotherEmail_START";
export const GetParentDetailsByMotherEmail_SUCCESS = "GetParentDetailsByMotherEmail_SUCCESS";
export const GetParentDetailsByMotherEmail_FAIL = "GetParentDetailsByMotherEmail_FAIL";

export const GetStudentHostByHostEmail_START = "GetStudentHostByHostEmail_START";
export const GetStudentHostByHostEmail_SUCCESS = "GetStudentHostByHostEmail_SUCCESS";
export const GetStudentHostByHostEmail_FAIL = "GetStudentHostByHostEmail_FAIL";


export const GetStudentHostByHostContact_START = "GetStudentHostByHostContact_START";
export const GetStudentHostByHostContact_SUCCESS = "GetStudentHostByHostContact_SUCCESS";
export const GetStudentHostByHostContact_FAIL = "GetStudentHostByHostContact_FAIL";



export const GetAllStudentCountForChart_START = "GetAllStudentCountForChart_START";
export const GetAllStudentCountForChart_SUCCESS = "GetAllStudentCountForChart_SUCCESS";
export const GetAllStudentCountForChart_FAIL = "GetAllStudentCountForChart_FAIL";

export const ChangeStudentsDormitory_START = "ChangeStudentsDormitory_START";
export const ChangeStudentsDormitory_SUCCESS = "ChangeStudentsDormitory_SUCCESS";
export const ChangeStudentsDormitory_FAIL = "ChangeStudentsDormitory_FAIL";

export const RemoveParentAddress_START = "RemoveParentAddress_START";
export const RemoveParentAddress_SUCCESS = "RemoveParentAddress_SUCCESS";
export const RemoveParentAddress_FAIL = "RemoveParentAddress_FAIL";

export const DeleteStudent_START = "DeleteStudent_START";
export const DeleteStudent_SUCCESS = "DeleteStudent_SUCCESS";
export const DeleteStudent_FAIL = "DeleteStudent_FAIL";



/*** School Admin Allergic Student ***/


export const GetAllStudentsAllergy_START = "GetAllStudentsAllergy_START";
export const GetAllStudentsAllergy_SUCCESS = "GetAllStudentsAllergy_SUCCESS";
export const GetAllStudentsAllergy_FAIL = "GetAllStudentsAllergy_FAIL";


export const AddStudentNewAllergyDetails_START = "AddStudentNewAllergyDetails_START";
export const AddStudentNewAllergyDetails_SUCCESS = "AddStudentNewAllergyDetails_SUCCESS";
export const AddStudentNewAllergyDetails_FAIL = "AddStudentNewAllergyDetails_FAIL";



/*** School Admin Generic Student ***/

export const GetAllStudentsGeneric_START = "GetAllStudentsGeneric_START";
export const GetAllStudentsGeneric_SUCCESS = "GetAllStudentsGeneric_SUCCESS";
export const GetAllStudentsGeneric_FAIL = "GetAllStudentsGeneric_FAIL";


export const CreateStudentGeneric_START = "CreateStudentGeneric_START";
export const CreateStudentGeneric_SUCCESS = "CreateStudentGeneric_SUCCESS";
export const CreateStudentGeneric_FAIL = "CreateStudentGeneric_FAIL";


export const GetAllStudentsGenericList_START = "GetAllStudentsGenericList_START";
export const GetAllStudentsGenericList_SUCCESS = "GetAllStudentsGenericList_SUCCESS";
export const GetAllStudentsGenericList_FAIL = "GetAllStudentsGenericList_FAIL";


export const DeleteStudentGeneric_START = "DeleteStudentGeneric_START";
export const DeleteStudentGeneric_SUCCESS = "DeleteStudentGeneric_SUCCESS";
export const DeleteStudentGeneric_FAIL = "DeleteStudentGeneric_FAIL";


export const UpdateStudentGenericStatus_START = "UpdateStudentGenericStatus_START";
export const UpdateStudentGenericStatus_SUCCESS = "UpdateStudentGenericStatus_SUCCESS";
export const UpdateStudentGenericStatus_FAIL = "UpdateStudentGenericStatus_FAIL";


export const GetStudentGenericById_START = "GetStudentGenericById_START";
export const GetStudentGenericById_SUCCESS = "GetStudentGenericById_SUCCESS";
export const GetStudentGenericById_FAIL = "GetStudentGenericById_FAIL";


export const UpdateStudentGeneric_START = "UpdateStudentGeneric_START";
export const UpdateStudentGeneric_SUCCESS = "UpdateStudentGeneric_SUCCESS";
export const UpdateStudentGeneric_FAIL = "UpdateStudentGeneric_FAIL";



/*** School Admin Grounded Student ***/

export const GetAllStudentsGrounded_START = "GetAllStudentsGrounded_START";
export const GetAllStudentsGrounded_SUCCESS = "GetAllStudentsGrounded_SUCCESS";
export const GetAllStudentsGrounded_FAIL = "GetAllStudentsGrounded_FAIL";


export const CreateStudentGrounded_START = "CreateStudentGrounded_START";
export const CreateStudentGrounded_SUCCESS = "CreateStudentGrounded_SUCCESS";
export const CreateStudentGrounded_FAIL = "CreateStudentGrounded_FAIL";


export const GetAllGroundedStudentsList_START = "GetAllGroundedStudentsList_START";
export const GetAllGroundedStudentsList_SUCCESS = "GetAllGroundedStudentsList_SUCCESS";
export const GetAllGroundedStudentsList_FAIL = "GetAllGroundedStudentsList_FAIL";


export const UpdateGroundedStudentStatus_START = "UpdateGroundedStudentStatus_START";
export const UpdateGroundedStudentStatus_SUCCESS = "UpdateGroundedStudentStatus_SUCCESS";
export const UpdateGroundedStudentStatus_FAIL = "UpdateGroundedStudentStatus_FAIL";


export const DeleteStudentGrounded_START = "DeleteStudentGrounded_START";
export const DeleteStudentGrounded_SUCCESS = "DeleteStudentGrounded_SUCCESS";
export const DeleteStudentGrounded_FAIL = "DeleteStudentGrounded_FAIL";


export const GetGroundedStudentById_START = "GetGroundedStudentById_START";
export const GetGroundedStudentById_SUCCESS = "GetGroundedStudentById_SUCCESS";
export const GetGroundedStudentById_FAIL = "GetGroundedStudentById_FAIL";


export const UpdateStudentGroundedDetails_START = "UpdateStudentGroundedDetails_START";
export const UpdateStudentGroundedDetails_SUCCESS = "UpdateStudentGroundedDetails_SUCCESS";
export const UpdateStudentGroundedDetails_FAIL = "UpdateStudentGroundedDetails_FAIL";




/*** School Admin Duplicate Student ***/

export const GetAllDuplicateStudents_START = "GetAllDuplicateStudents_START";
export const GetAllDuplicateStudents_SUCCESS = "GetAllDuplicateStudents_SUCCESS";
export const GetAllDuplicateStudents_FAIL = "GetAllDuplicateStudents_FAIL";


export const UpdateStudentDuplicateStatus_START = "UpdateStudentDuplicateStatus_START";
export const UpdateStudentDuplicateStatus_SUCCESS = "UpdateStudentDuplicateStatus_SUCCESS";
export const UpdateStudentDuplicateStatus_FAIL = "UpdateStudentDuplicateStatus_FAIL";



/*** School Admin RollOver Student ***/

export const StudentsRollOver_START = "StudentsRollOver_START";
export const StudentsRollOver_SUCCESS = "StudentsRollOver_SUCCESS";
export const StudentsRollOver_FAIL = "StudentsRollOver_FAIL";

export const GetAllClassesDetailsRollOver_START = "GetAllClassesDetailsRollOver_START";
export const GetAllClassesDetailsRollOver_SUCCESS = "GetAllClassesDetailsRollOver_SUCCESS";
export const GetAllClassesDetailsRollOver_FAIL = "GetAllClassesDetailsRollOver_FAIL";

/*** School Admin Import Student ***/


export const GetAllDormitoryImport_START = "GetAllDormitoryImport_START";
export const GetAllDormitoryImport_SUCCESS = "GetAllDormitoryImport_SUCCESS";
export const GetAllDormitoryImport_FAIL = "GetAllDormitoryImport_FAIL";


export const GetAllManagersBySchoolIdImport_START = "GetAllManagersBySchoolIdImport_START";
export const GetAllManagersBySchoolIdImport_SUCCESS = "GetAllManagersBySchoolIdImport_SUCCESS";
export const GetAllManagersBySchoolIdImport_FAIL = "GetAllManagersBySchoolIdImport_FAIL";

export const GetAllClassesDetailsImport_START = "GetAllClassesDetailsImport_START";
export const GetAllClassesDetailsImport_SUCCESS = "GetAllClassesDetailsImport_SUCCESS";
export const GetAllClassesDetailsImport_FAIL = "GetAllClassesDetailsImport_FAIL";

export const ImportStudentsCsv_START = "ImportStudentsCsv_START";
export const ImportStudentsCsv_SUCCESS = "ImportStudentsCsv_SUCCESS";
export const ImportStudentsCsv_FAIL = "ImportStudentsCsv_FAIL";

export const ExportManagersPdf_START = "ExportManagersPdf_START";
export const ExportManagersPdf_SUCCESS = "ExportManagersPdf_SUCCESS";
export const ExportManagersPdf_FAIL = "ExportManagersPdf_FAIL";



/*** School Admin Messages ***/


export const CreateUserMessage_START = "CreateUserMessage_START";
export const CreateUserMessage_SUCCESS = "CreateUserMessage_SUCCESS";
export const CreateUserMessage_FAIL = "CreateUserMessage_FAIL";

export const GetAllUserMessages_START = "GetAllUserMessages_START";
export const GetAllUserMessages_SUCCESS = "GetAllUserMessages_SUCCESS";
export const GetAllUserMessages_FAIL = "GetAllUserMessages_FAIL";

export const GetUserMessageById_START = "GetUserMessageById_START";
export const GetUserMessageById_SUCCESS = "GetUserMessageById_SUCCESS";
export const GetUserMessageById_FAIL = "GetUserMessageById_FAIL";

export const DeleteUserMessage_START = "DeleteUserMessage_START";
export const DeleteUserMessage_SUCCESS = "DeleteUserMessage_SUCCESS";
export const DeleteUserMessage_FAIL = "DeleteUserMessage_FAIL";


export const UpdateUserMessage_START = "UpdateUserMessage_START";
export const UpdateUserMessage_SUCCESS = "UpdateUserMessage_SUCCESS";
export const UpdateUserMessage_FAIL = "UpdateUserMessage_FAIL";


export const UpdateUserMessageStatus_START = "UpdateUserMessageStatus_START";
export const UpdateUserMessageStatus_SUCCESS = "UpdateUserMessageStatus_SUCCESS";
export const UpdateUserMessageStatus_FAIL = "UpdateUserMessageStatus_FAIL";

/*** School Admin Custom Link ***/


export const CreateCustomLink_START = "CreateCustomLink_START";
export const CreateCustomLink_SUCCESS = "CreateCustomLink_SUCCESS";
export const CreateCustomLink_FAIL = "CreateCustomLink_FAIL";


export const GetAllCustomLinks_START = "GetAllCustomLinks_START";
export const GetAllCustomLinks_SUCCESS = "GetAllCustomLinks_SUCCESS";
export const GetAllCustomLinks_FAIL = "GetAllCustomLinks_FAIL";

export const GetCustomLinkById_START = "GetCustomLinkById_START";
export const GetCustomLinkById_SUCCESS = "GetCustomLinkById_SUCCESS";
export const GetCustomLinkById_FAIL = "GetCustomLinkById_FAIL";

export const UpdateCustomLink_START = "UpdateCustomLink_START";
export const UpdateCustomLink_SUCCESS = "UpdateCustomLink_SUCCESS";
export const UpdateCustomLink_FAIL = "UpdateCustomLink_FAIL";   

export const DeleteCustomLink_START = "DeleteCustomLink_START";
export const DeleteCustomLink_SUCCESS = "DeleteCustomLink_SUCCESS";
export const DeleteCustomLink_FAIL = "DeleteCustomLink_FAIL"; 

/*** School Admin Change Dormitory ***/

export const GetStudentsByDormitoryId_START = "GetStudentsByDormitoryId_START";
export const GetStudentsByDormitoryId_SUCCESS = "GetStudentsByDormitoryId_SUCCESS";
export const GetStudentsByDormitoryId_FAIL = "GetStudentsByDormitoryId_FAIL";

/*** School Admin Student Card ***/

export const GetAllStudentsForCard_START = "GetAllStudentsForCard_START";
export const GetAllStudentsForCard_SUCCESS = "GetAllStudentsForCard_SUCCESS";
export const GetAllStudentsForCard_FAIL = "GetAllStudentsForCard_FAIL";


/*** School Admin On-Campus Location ***/

export const CreateOnCampusLocation_START = "CreateOnCampusLocation_START";
export const CreateOnCampusLocation_SUCCESS = "CreateOnCampusLocation_SUCCESS";
export const CreateOnCampusLocation_FAIL = "CreateOnCampusLocation_FAIL";

export const GetAllOnCampusLocation_START = "GetAllOnCampusLocation_START";
export const GetAllOnCampusLocation_SUCCESS = "GetAllOnCampusLocation_SUCCESS";
export const GetAllOnCampusLocation_FAIL = "GetAllOnCampusLocation_FAIL";

export const UpdateOnCampusLocationStatus_START = "UpdateOnCampusLocationStatus_START";
export const UpdateOnCampusLocationStatus_SUCCESS = "UpdateOnCampusLocationStatus_SUCCESS";
export const UpdateOnCampusLocationStatus_FAIL = "UpdateOnCampusLocationStatus_FAIL";

export const DeleteOnCampusLocation_START = "DeleteOnCampusLocation_START";
export const DeleteOnCampusLocation_SUCCESS = "DeleteOnCampusLocation_SUCCESS";
export const DeleteOnCampusLocation_FAIL = "DeleteOnCampusLocation_FAIL";

export const GetOnCampusLocationById_START = "GetOnCampusLocationById_START";
export const GetOnCampusLocationById_SUCCESS = "GetOnCampusLocationById_SUCCESS";
export const GetOnCampusLocationById_FAIL = "GetOnCampusLocationById_FAIL";

export const UpdateOnCampusLocationDetails_START = "UpdateOnCampusLocationDetails_START";
export const UpdateOnCampusLocationDetails_SUCCESS = "UpdateOnCampusLocationDetails_SUCCESS";
export const UpdateOnCampusLocationDetails_FAIL = "UpdateOnCampusLocationDetails_FAIL";



/*** School Admin Roll-Call ***/

export const CreateOrUpdateRollCall_START = "CreateOrUpdateRollCall_START";
export const CreateOrUpdateRollCall_SUCCESS = "CreateOrUpdateRollCall_SUCCESS";
export const CreateOrUpdateRollCall_FAIL = "CreateOrUpdateRollCall_FAIL";

export const GetRollCallDetails_START = "GetRollCallDetails_START";
export const GetRollCallDetails_SUCCESS = "GetRollCallDetails_SUCCESS";
export const GetRollCallDetails_FAIL = "GetRollCallDetails_FAIL";


/*** School Admin Comments dashboard ***/

export const CreateOperationalComment_START = "CreateOperationalComment_START";
export const CreateOperationalComment_SUCCESS = "CreateOperationalComment_SUCCESS";
export const CreateOperationalComment_FAIL = "CreateOperationalComment_FAIL";

export const CreateDiaryPastoralComments_START = "CreateDiaryPastoralComments_START";
export const CreateDiaryPastoralComments_SUCCESS = "CreateDiaryPastoralComments_SUCCESS";
export const CreateDiaryPastoralComments_FAIL = "CreateDiaryPastoralComments_FAIL";

export const CreateDiaryMedicalComment_START = "CreateDiaryMedicalComment_START";
export const CreateDiaryMedicalComment_SUCCESS = "CreateDiaryMedicalComment_SUCCESS";
export const CreateDiaryMedicalComment_FAIL = "CreateDiaryMedicalComment_FAIL";

export const GetAllOperationalComments_START = "GetAllOperationalComments_START";
export const GetAllOperationalComments_SUCCESS = "GetAllOperationalComments_SUCCESS";
export const GetAllOperationalComments_FAIL = "GetAllOperationalComments_FAIL";

export const GetAllDiaryPastoralComments_START = "GetAllDiaryPastoralComments_START";
export const GetAllDiaryPastoralComments_SUCCESS = "GetAllDiaryPastoralComments_SUCCESS";
export const GetAllDiaryPastoralComments_FAIL = "GetAllDiaryPastoralComments_FAIL";

export const GetAllDiaryMedicalComments_START = "GetAllDiaryMedicalComments_START";
export const GetAllDiaryMedicalComments_SUCCESS = "GetAllDiaryMedicalComments_SUCCESS";
export const GetAllDiaryMedicalComments_FAIL = "GetAllDiaryMedicalComments_FAIL";


/*** School Admin Event-Calendar ***/

export const CreateEventCalendar_START = "CreateEventCalendar_START";
export const CreateEventCalendar_SUCCESS = "CreateEventCalendar_SUCCESS";
export const CreateEventCalendar_FAIL = "CreateEventCalendar_FAIL";


/*** School Admin Dashboard ***/

export const GetStudentsBirthdayDetails_START = "GetStudentsBirthdayDetails_START";
export const GetStudentsBirthdayDetails_SUCCESS = "GetStudentsBirthdayDetails_SUCCESS";
export const GetStudentsBirthdayDetails_FAIL = "GetStudentsBirthdayDetails_FAIL";

export const GetAllEventCalendarDetails_START = "GetAllEventCalendarDetails_START";
export const GetAllEventCalendarDetails_SUCCESS = "GetAllEventCalendarDetails_SUCCESS";
export const GetAllEventCalendarDetails_FAIL = "GetAllEventCalendarDetails_FAIL";

export const UpdateEventCalendarDetails_START = "UpdateEventCalendarDetails_START";
export const UpdateEventCalendarDetails_SUCCESS = "UpdateEventCalendarDetails_SUCCESS";
export const UpdateEventCalendarDetails_FAIL = "UpdateEventCalendarDetails_FAIL";

export const DeleteEventCalendarDetails_START = "DeleteEventCalendarDetails_START";
export const DeleteEventCalendarDetails_SUCCESS = "DeleteEventCalendarDetails_SUCCESS";
export const DeleteEventCalendarDetails_FAIL = "DeleteEventCalendarDetails_FAIL";



/*** School Admin Leave ***/

export const CreateTravelMode_START = "CreateTravelMode_START";
export const CreateTravelMode_SUCCESS = "CreateTravelMode_SUCCESS";
export const CreateTravelMode_FAIL = "CreateTravelMode_FAIL";

export const GetAllTravelModes_START = "GetAllTravelModes_START";
export const GetAllTravelModes_SUCCESS = "GetAllTravelModes_SUCCESS";
export const GetAllTravelModes_FAIL = "GetAllTravelModes_FAIL";

export const GetTravelModeById_START = "GetTravelModeById_START";
export const GetTravelModeById_SUCCESS = "GetTravelModeById_SUCCESS";
export const GetTravelModeById_FAIL = "GetTravelModeById_FAIL";

export const UpdateTravelMode_START = "UpdateTravelMode_START";
export const UpdateTravelMode_SUCCESS = "UpdateTravelMode_SUCCESS";
export const UpdateTravelMode_FAIL = "UpdateTravelMode_FAIL";

export const UpdateTravelModeStatus_START = "UpdateTravelModeStatus_START";
export const UpdateTravelModeStatus_SUCCESS = "UpdateTravelModeStatus_SUCCESS";
export const UpdateTravelModeStatus_FAIL = "UpdateTravelModeStatus_FAIL";

export const DeleteTravelMode_START = "DeleteTravelMode_START";
export const DeleteTravelMode_SUCCESS = "DeleteTravelMode_SUCCESS";
export const DeleteTravelMode_FAIL = "DeleteTravelMode_FAIL";

export const CreateLeaveRejectReason_START = "CreateLeaveRejectReason_START";
export const CreateLeaveRejectReason_SUCCESS = "CreateLeaveRejectReason_SUCCESS";
export const CreateLeaveRejectReason_FAIL = "CreateLeaveRejectReason_FAIL";

export const GetAllLeaveRejectReasons_START = "GetAllLeaveRejectReasons_START";
export const GetAllLeaveRejectReasons_SUCCESS = "GetAllLeaveRejectReasons_SUCCESS";
export const GetAllLeaveRejectReasons_FAIL = "GetAllLeaveRejectReasons_FAIL";

export const GetLeaveRejectReasonById_START = "GetLeaveRejectReasonById_START";
export const GetLeaveRejectReasonById_SUCCESS = "GetLeaveRejectReasonById_SUCCESS";
export const GetLeaveRejectReasonById_FAIL = "GetLeaveRejectReasonById_FAIL";

export const UpdateLeaveRejectReason_START = "UpdateLeaveRejectReason_START";
export const UpdateLeaveRejectReason_SUCCESS = "UpdateLeaveRejectReason_SUCCESS";
export const UpdateLeaveRejectReason_FAIL = "UpdateLeaveRejectReason_FAIL";

export const UpdateLeaveRejectStatus_START = "UpdateLeaveRejectStatus_START";
export const UpdateLeaveRejectStatus_SUCCESS = "UpdateLeaveRejectStatus_SUCCESS";
export const UpdateLeaveRejectStatus_FAIL = "UpdateLeaveRejectStatus_FAIL";

export const DeleteLeaveRejectReason_START = "DeleteLeaveRejectReason_START";
export const DeleteLeaveRejectReason_SUCCESS = "DeleteLeaveRejectReason_SUCCESS";
export const DeleteLeaveRejectReason_FAIL = "DeleteLeaveRejectReason_FAIL";

export const CreateActivity_START = "CreateActivity_START";
export const CreateActivity_SUCCESS = "CreateActivity_SUCCESS";
export const CreateActivity_FAIL = "CreateActivity_FAIL";

export const GetAllActivity_START = "GetAllActivity_START";
export const GetAllActivity_SUCCESS = "GetAllActivity_SUCCESS";
export const GetAllActivity_FAIL = "GetAllActivity_FAIL";

/*** manage host ***/


export const ApproveHost_START = "ApproveHost_START";
export const ApproveHost_SUCCESS = "ApproveHost_SUCCESS";
export const ApproveHost_FAIL = "ApproveHost_FAIL";

export const GetAllStudentsHost_START = "GetAllStudentsHost_START";
export const GetAllStudentsHost_SUCCESS = "GetAllStudentsHost_SUCCESS";
export const GetAllStudentsHost_FAIL = "GetAllStudentsHost_FAIL";

export const UpdateStudentHostStatus_START = "UpdateStudentHostStatus_START";
export const UpdateStudentHostStatus_SUCCESS = "UpdateStudentHostStatus_SUCCESS";
export const UpdateStudentHostStatus_FAIL = "UpdateStudentHostStatus_FAIL";





/*** School Admin Snapshot ***/

export const GetAllStudentsforConductRollCall_START = "GetAllStudentsforConductRollCall_START";
export const GetAllStudentsforConductRollCall_SUCCESS = "GetAllStudentsforConductRollCall_SUCCESS";
export const GetAllStudentsforConductRollCall_FAIL = "GetAllStudentsforConductRollCall_FAIL";

export const CreateStudentsConductRollCall_START = "CreateStudentsConductRollCall_START";
export const CreateStudentsConductRollCall_SUCCESS = "CreateStudentsConductRollCall_SUCCESS";
export const CreateStudentsConductRollCall_FAIL = "CreateStudentsConductRollCall_FAIL";

export const GetStudentsRollCallReport_START = "GetStudentsRollCallReport_START";
export const GetStudentsRollCallReport_SUCCESS = "GetStudentsRollCallReport_SUCCESS";
export const GetStudentsRollCallReport_FAIL = "GetStudentsRollCallReport_FAIL";

export const CreateRollCallSessionDiscard_START = "CreateRollCallSessionDiscard_START";
export const CreateRollCallSessionDiscard_SUCCESS = "CreateRollCallSessionDiscard_SUCCESS";
export const CreateRollCallSessionDiscard_FAIL = "CreateRollCallSessionDiscard_FAIL";

export const GetAllDashboardDiscadedAttendance_START = "GetAllDashboardDiscadedAttendance_START";
export const GetAllDashboardDiscadedAttendance_SUCCESS = "GetAllDashboardDiscadedAttendance_SUCCESS";
export const GetAllDashboardDiscadedAttendance_FAIL = "GetAllDashboardDiscadedAttendance_FAIL";

export const ExportStudentsAttendancePdf_START = "ExportStudentsAttendancePdf_START";
export const ExportStudentsAttendancePdf_SUCCESS = "ExportStudentsAttendancePdf_SUCCESS";
export const ExportStudentsAttendancePdf_FAIL = "ExportStudentsAttendancePdf_FAIL";

export const GetAllSnapshotStudents_START = "GetAllSnapshotStudents_START";
export const GetAllSnapshotStudents_SUCCESS = "GetAllSnapshotStudents_SUCCESS";
export const GetAllSnapshotStudents_FAIL = "GetAllSnapshotStudents_FAIL";

export const ExportStudentLocationHistoryPdf_START = "ExportStudentLocationHistoryPdf_START";
export const ExportStudentLocationHistoryPdf_SUCCESS = "ExportStudentLocationHistoryPdf_SUCCESS";
export const ExportStudentLocationHistoryPdf_FAIL = "ExportStudentLocationHistoryPdf_FAIL";

export const SignInStudentReasonOrOncampus_START = "SignInStudentReasonOrOncampus_START";
export const SignInStudentReasonOrOncampus_SUCCESS = "SignInStudentReasonOrOncampus_SUCCESS";
export const SignInStudentReasonOrOncampus_FAIL = "SignInStudentReasonOrOncampus_FAIL";

export const SignOutStudentReasonOrOncampus_START = "SignOutStudentReasonOrOncampus_START";
export const SignOutStudentReasonOrOncampus_SUCCESS = "SignOutStudentReasonOrOncampus_SUCCESS";
export const SignOutStudentReasonOrOncampus_FAIL = "SignOutStudentReasonOrOncampus_FAIL";

export const UpdateSnapshotRollCallCompareStatus_START = "UpdateSnapshotRollCallCompareStatus_START";
export const UpdateSnapshotRollCallCompareStatus_SUCCESS = "UpdateSnapshotRollCallCompareStatus_SUCCESS";
export const UpdateSnapshotRollCallCompareStatus_FAIL = "UpdateSnapshotRollCallCompareStatus_FAIL";

export const SnapshotSignInOrSignOutStudents_START = "SnapshotSignInOrSignOutStudents_START";
export const SnapshotSignInOrSignOutStudents_SUCCESS = "SnapshotSignInOrSignOutStudents_SUCCESS";
export const SnapshotSignInOrSignOutStudents_FAIL = "SnapshotSignInOrSignOutStudents_FAIL";



/*** Settings Report ***/
export const GetWeeklyAbsentReasonForChart_START = "GetWeeklyAbsentReasonForChart_START";
export const GetWeeklyAbsentReasonForChart_SUCCESS = "GetWeeklyAbsentReasonForChart_SUCCESS";
export const GetWeeklyAbsentReasonForChart_FAIL = "GetWeeklyAbsentReasonForChart_FAIL";

export const GetWeeklyAttendanceCountForChart_START = "GetWeeklyAttendanceCountForChart_START";
export const GetWeeklyAttendanceCountForChart_SUCCESS = "GetWeeklyAttendanceCountForChart_SUCCESS";
export const GetWeeklyAttendanceCountForChart_FAIL = "GetWeeklyAttendanceCountForChart_FAIL";

export const GetMonthlyAbsentReasonForChart_START = "GetMonthlyAbsentReasonForChart_START";
export const GetMonthlyAbsentReasonForChart_SUCCESS = "GetMonthlyAbsentReasonForChart_SUCCESS";
export const GetMonthlyAbsentReasonForChart_FAIL = "GetMonthlyAbsentReasonForChart_FAIL";

export const GetYearlyAbsentReasonForChart_START = "GetYearlyAbsentReasonForChart_START";
export const GetYearlyAbsentReasonForChart_SUCCESS = "GetYearlyAbsentReasonForChart_SUCCESS";
export const GetYearlyAbsentReasonForChart_FAIL = "GetYearlyAbsentReasonForChart_FAIL";

export const GetMonthlyAttendanceCountForChart_START = "GetMonthlyAttendanceCountForChart_START";
export const GetMonthlyAttendanceCountForChart_SUCCESS = "GetMonthlyAttendanceCountForChart_SUCCESS";
export const GetMonthlyAttendanceCountForChart_FAIL = "GetMonthlyAttendanceCountForChart_FAIL";

export const GetYearlyAttendanceCountForChart_START = "GetYearlyAttendanceCountForChart_START";
export const GetYearlyAttendanceCountForChart_SUCCESS = "GetYearlyAttendanceCountForChart_SUCCESS";
export const GetYearlyAttendanceCountForChart_FAIL = "GetYearlyAttendanceCountForChart_FAIL";


/*** Permissions ***/


export const GetManagerPermissonDetailsById_START = "GetManagerPermissonDetailsById_START";
export const GetManagerPermissonDetailsById_SUCCESS = "GetManagerPermissonDetailsById_SUCCESS";
export const GetManagerPermissonDetailsById_FAIL = "GetManagerPermissonDetailsById_FAIL";

export const UpdateManagerPermissions_START = "UpdateManagerPermissions_START";
export const UpdateManagerPermissions_SUCCESS = "UpdateManagerPermissions_SUCCESS";
export const UpdateManagerPermissions_FAIL = "UpdateManagerPermissions_FAIL";

export const GetLoginUserPermissions_START = "GetLoginUserPermissions_START";
export const GetLoginUserPermissions_SUCCESS = "GetLoginUserPermissions_SUCCESS";
export const GetLoginUserPermissions_FAIL = "GetLoginUserPermissions_FAIL";

export const GetManagerPermissonHistoryById_START = "GetManagerPermissonHistoryById_START";
export const GetManagerPermissonHistoryById_SUCCESS = "GetManagerPermissonHistoryById_SUCCESS";
export const GetManagerPermissonHistoryById_FAIL = "GetManagerPermissonHistoryById_FAIL";

export const GetAllClassesListForSnapshot_START = "GetAllClassesListForSnapshot_START";
export const GetAllClassesListForSnapshot_SUCCESS = "GetAllClassesListForSnapshot_SUCCESS";
export const GetAllClassesListForSnapshot_FAIL = "GetAllClassesListForSnapshot_FAIL";

export const CreateRole_START = "CreateRole_START";
export const CreateRole_SUCCESS = "CreateRole_SUCCESS";
export const CreateRole_FAIL = "CreateRole_FAIL";

export const GetAllRoleList_START = "GetAllRoleList_START";
export const GetAllRoleList_SUCCESS = "GetAllRoleList_SUCCESS";
export const GetAllRoleList_FAIL = "GetAllRoleList_FAIL";

export const GetAllRoleDetails_START = "GetAllRoleDetails_START";
export const GetAllRoleDetails_SUCCESS = "GetAllRoleDetails_SUCCESS";
export const GetAllRoleDetails_FAIL = "GetAllRoleDetails_FAIL";

export const GetPermissionsByRoleId_START = "GetPermissionsByRoleId_START";
export const GetPermissionsByRoleId_SUCCESS = "GetPermissionsByRoleId_SUCCESS";
export const GetPermissionsByRoleId_FAIL = "GetPermissionsByRoleId_FAIL";

export const UpdateRole_START = "UpdateRole_START";
export const UpdateRole_SUCCESS = "UpdateRole_SUCCESS";
export const UpdateRole_FAIL = "UpdateRole_FAIL";

export const UpdateRoleStatus_START = "UpdateRoleStatus_START";
export const UpdateRoleStatus_SUCCESS = "UpdateRoleStatus_SUCCESS";
export const UpdateRoleStatus_FAIL = "UpdateRoleStatus_FAIL";

export const CreateOrUpdateRolePermissions_START = "CreateOrUpdateRolePermissions_START";
export const CreateOrUpdateRolePermissions_SUCCESS = "CreateOrUpdateRolePermissions_SUCCESS";
export const CreateOrUpdateRolePermissions_FAIL = "CreateOrUpdateRolePermissionse_FAIL";

export const GetRoleAndPermissionList_START = "GetRoleAndPermissionList_START";
export const GetRoleAndPermissionList_SUCCESS = "GetRoleAndPermissionList_SUCCESS";
export const GetRoleAndPermissionList_FAIL = "GetRoleAndPermissionList_FAIL";







// /*** Const ENV ***/
// export const siteurl = process.env.REACT_APP_API_URL;
// export const environment_url = process.env.REACT_APP_ENVIRONMENT_URL;
// export const google_client_secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
// export const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/*** Const ENV ***/
// export const siteurl = process.env.REACT_APP_API_URL;
export const siteurl = "https://bssnew.dikonia.in";
// export const environment_url = process.env.REACT_APP_ENVIRONMENT_URL;
export const environment_url = "https://bssnew.dikonia.in";
export const google_client_secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;


export const socket = io.connect("https://bssnew.dikonia.in", 
// console.log("checking connection"),
{
//     // forceNew: true,
//     // transports: ["polling"],
// transports: ['websocket', 'polling']
 }
 );

// console.log("IO", socket)







/*** Allergy edit ***/

export const DeleteStudentAllergyById_START = "DeleteStudentAllergyById_START";
export const DeleteStudentAllergyById_SUCCESS = "DeleteStudentAllergyById_SUCCESS";
export const DeleteStudentAllergyById_FAIL = "DeleteStudentAllergyById_FAIL";


/*** updata and edit host details ***/

export const AddOrUpdateStudentHost_START = "AddOrUpdateStudentHost_START";
export const AddOrUpdateStudentHost_SUCCESS = "AddOrUpdateStudentHost_SUCCESS";
export const AddOrUpdateStudentHost_FAIL = "AddOrUpdateStudentHost_FAIL";


/*** GetAllergicStudentDetailById ***/

export const GetAllergicStudentDetailById_START = "GetAllergicStudentDetailById_START";
export const GetAllergicStudentDetailById_SUCCESS = "GetAllergicStudentDetailById_SUCCESS";
export const GetAllergicStudentDetailById_FAIL = "GetAllergicStudentDetailById_FAIL";


/*** GetAllergicStudentDetailById ***/

export const GetAllAllergicStudentsDetail_START = "GetAllAllergicStudentsDetail_START";
export const GetAllAllergicStudentsDetail_SUCCESS = "GetAllAllergicStudentsDetail_SUCCESS";
export const GetAllAllergicStudentsDetail_FAIL = "GetAllAllergicStudentsDetail_FAIL";


/*** GetAllNonAllergicStudentsList ***/

export const GetAllNonAllergicStudentsList_START = "GetAllNonAllergicStudentsList_START";
export const GetAllNonAllergicStudentsList_SUCCESS = "GetAllNonAllergicStudentsList_SUCCESS";
export const GetAllNonAllergicStudentsList_FAIL = "GetAllNonAllergicStudentsList_FAIL";


/*** AddOrUpdateStudentAllergyDetails ***/

export const AddOrUpdateStudentAllergyDetails_START = "AddOrUpdateStudentAllergyDetails_START";
export const AddOrUpdateStudentAllergyDetails_SUCCESS = "AddOrUpdateStudentAllergyDetails_SUCCESS";
export const AddOrUpdateStudentAllergyDetails_FAIL = "AddOrUpdateStudentAllergyDetails_FAIL";


/*** RemoveAllAllergyOfStudent  ***/
export const RemoveAllAllergyOfStudent_START = "RemoveAllAllergyOfStudent_START";
export const RemoveAllAllergyOfStudent_SUCCESS = "RemoveAllAllergyOfStudent_SUCCESS";
export const RemoveAllAllergyOfStudent_FAIL = "RemoveAllAllergyOfStudent_FAIL";


/***  GetStudentDiaryCommentsById ***/

export const GetStudentDiaryCommentsById_START = "AddOrUpdateStudentAllergyDetails_START";
export const GetStudentDiaryCommentsById_SUCCESS = "AddOrUpdateStudentAllergyDetails_SUCCESS";
export const GetStudentDiaryCommentsById_FAIL = "AddOrUpdateStudentAllergyDetails_FAIL";


/***  GetDashboardStudentsMedicalIssued ***/

export const GetDashboardStudentsMedicalIssued_START = "GetDashboardStudentsMedicalIssued_START";
export const GetDashboardStudentsMedicalIssued_SUCCESS = "GetDashboardStudentsMedicalIssued_SUCCESS";
export const GetDashboardStudentsMedicalIssued_FAIL = "GetDashboardStudentsMedicalIssued_FAIL";


/*** GenerateAllergicStudentsReport ***/

export const GenerateAllergicStudentsReport_START = "GenerateAllergicStudentsReport_START";
export const GenerateAllergicStudentsReport_SUCCESS = "GenerateAllergicStudentsReport_SUCCESS";
export const GenerateAllergicStudentsReport_FAIL = "GenerateAllergicStudentsReport_FAIL";


/*** GetUserAttendanceCountForChart ***/

export const GetUserAttendanceCountForChart_START = "GetUserAttendanceCountForChart_START";
export const GetUserAttendanceCountForChart_SUCCESS = "GetUserAttendanceCountForChart_SUCCESS";
export const GetUserAttendanceCountForChart_FAIL = "GetUserAttendanceCountForChart_FAIL";


/*** GetSystemLogs ***/

export const GetSystemLogs_START = "GetSystemLogs_START";
export const GetSystemLogs_SUCCESS = "GetSystemLogs_SUCCESS";
export const GetSystemLogs_FAIL = "GetSystemLogs_FAIL";


/***  UpdateDiaryMedicalComments ***/

export const UpdateDiaryMedicalComments_START = "UpdateDiaryMedicalComments_START";
export const UpdateDiaryMedicalComments_SUCCESS = "UpdateDiaryMedicalComments_SUCCESS";
export const UpdateDiaryMedicalComments_FAIL = "UpdateDiaryMedicalComments_FAIL";


/*** WeekWiseAttendanceReasonsChart ***/

export const WeekWiseAttendanceReasonsChart_START = "WeekWiseAttendanceReasonsChart_START";
export const WeekWiseAttendanceReasonsChart_SUCCESS = "WeekWiseAttendanceReasonsChart_SUCCESS";
export const WeekWiseAttendanceReasonsChart_FAIL = "WeekWiseAttendanceReasonsChart_FAIL";


/*** ExportWeeklyAttendanceReasonsPdf ***/

export const ExportWeeklyAttendanceReasonsPdf_START = "ExportWeeklyAttendanceReasonsPdf_START";
export const ExportWeeklyAttendanceReasonsPdf_SUCCESS = "ExportWeeklyAttendanceReasonsPdf_SUCCESS";
export const ExportWeeklyAttendanceReasonsPdf_FAIL = "ExportWeeklyAttendanceReasonsPdf_FAIL";


/*** ResetAllReasonsdata ***/

export const ResetAllReasonsdata_START = "ResetAllReasonsdata_START";
export const ResetAllReasonsdata_SUCCESS = "ResetAllReasonsdata_SUCCESS";
export const ResetAllReasonsdata_FAIL = "ResetAllReasonsdata_FAIL";


/*** GetStudentHostCsvFileById ***/

export const GetStudentHostCsvFileById_START = "GetStudentHostCsvFileById_START";
export const GetStudentHostCsvFileById_SUCCESS = "GetStudentHostCsvFileById_SUCCESS";
export const GetStudentHostCsvFileById_FAIL = "GetStudentHostCsvFileById_FAIL";


/*** GetStudentHostPdfById ***/

export const GetStudentHostPdfById_START = "GetStudentHostPdfById_START";
export const GetStudentHostPdfById_SUCCESS = "GetStudentHostPdfById_SUCCESS";
export const GetStudentHostPdfById_FAIL = "GetStudentHostPdfById_FAIL";


/*** GetStudentAttandenceById ***/

export const GetStudentAttandenceById_START = "GetStudentAttandenceById_START";
export const GetStudentAttandenceById_SUCCESS = "GetStudentAttandenceById_SUCCESS";
export const GetStudentAttandenceById_FAIL = "GetStudentAttandenceById_FAIL";

/*** GetStudentAttandencePdfReport ***/

export const GetStudentAttandencePdfByStudentId_START = "GetStudentAttandencePdfByStudentId_START";
export const GetStudentAttandencePdfByStudentId_SUCCESS = "GetStudentAttandencePdfByStudentId_SUCCESS";
export const GetStudentAttandencePdfByStudentId_FAIL = "GetStudentAttandencePdfByStudentId_FAIL";

/*** GenerateAllergicReportForDashboard ***/

export const GenerateAllergicReportForDashboard_START = "GenerateAllergicReportForDashboard_START";
export const GenerateAllergicReportForDashboard_SUCCESS = "GenerateAllergicReportForDashboard_SUCCESS";
export const GenerateAllergicReportForDashboard_FAIL = "GenerateAllergicReportForDashboard_FAIL";

/*** GetAllAllergyList ***/

export const GetAllAllergyList_START = "GetAllAllergyList_START";
export const GetAllAllergyList_SUCCESS = "GetAllAllergyList_SUCCESS";
export const GetAllAllergyList_FAIL = "GetAllAllergyList_FAIL";


/*** CreateDuplicateRoleAndPerm ***/

export const CreateDuplicateRoleAndPerm_START = "CreateDuplicateRoleAndPerm_START";
export const CreateDuplicateRoleAndPerm_SUCCESS = "CreateDuplicateRoleAndPerm_SUCCESS";
export const CreateDuplicateRoleAndPerm_FAIL = "CreateDuplicateRoleAndPerm_FAIL";


/***  BorderLeave ***/

export const GetAllBorderLeavesDetails_START = "GetAllBorderLeavesDetails_START";
export const GetAllBorderLeavesDetails_SUCCESS = "GetAllBorderLeavesDetails_SUCCESS";
export const GetAllBorderLeavesDetails_FAIL = "GetAllBorderLeavesDetails_FAIL";

export const GetBorderLeavesDetailById_START = "EditBoardersLeave_START";
export const GetBorderLeavesDetailById_SUCCESS = "EditBoardersLeave_SUCCESS";
export const GetBorderLeavesDetailById_FAIL = "EditBoardersLeave_FAIL";

export const EditBoardersLeave_START = "EditBoardersLeave_START";
export const EditBoardersLeave_SUCCESS = "EditBoardersLeave_SUCCESS";
export const EditBoardersLeave_FAIL = "EditBoardersLeave_FAIL";

export const BorderLeaveApproval_START = "BorderLeaveApproval_START";
export const BorderLeaveApproval_SUCCESS = "BorderLeaveApproval_SUCCESS";
export const BorderLeaveApproval_FAIL = "BorderLeaveApproval_FAIL";


export const ApplyBorderLeave_START = "ApplyBorderLeave_START";
export const ApplyBorderLeave_SUCCESS = "ApplyBorderLeave_SUCCESS";
export const ApplyBorderLeave_FAIL = "ApplyBorderLeave_FAIL";

export const GetApprovedStudentHost_START = "GetApprovedStudentHost_START";
export const GetApprovedStudentHost_SUCCESS = "GetApprovedStudentHost_SUCCESS";
export const GetApprovedStudentHost_FAIL = "GetApprovedStudentHost_FAIL";


export const ArchiveOrUnarchiveBorderLeave_START = "ArchiveOrUnarchiveBorderLeave_START";
export const ArchiveOrUnarchiveBorderLeave_SUCCESS = "ArchiveOrUnarchiveBorderLeave_SUCCESS";
export const ArchiveOrUnarchiveBorderLeave_FAIL = "ArchiveOrUnarchiveBorderLeave_FAIL";

export const GetImportStudentDetails_START = "GetImportStudentDetails_START";
export const GetImportStudentDetails_SUCCESS = "GetImportStudentDetails_SUCCESS";
export const GetImportStudentDetails_FAIL = "GetImportStudentDetails_FAIL";


export const GetArchiveStudentDetails_START = "GetArchiveStudentDetails_START";
export const GetArchiveStudentDetails_SUCCESS = "GetArchiveStudentDetails_SUCCESS";
export const GetArchiveStudentDetails_FAIL = "GetArchiveStudentDetails_FAIL";

export const GetDepartureStudentDetails_START = "GetDepartureStudentDetails_START";
export const GetDepartureStudentDetails_SUCCESS = "GetDepartureStudentDetails_SUCCESS";
export const GetDepartureStudentDetails_FAIL = "GetDepartureStudentDetails_FAIL";

export const GetReturnStudentDetails_START = "GetReturnStudentDetails_START";
export const GetReturnStudentDetails_SUCCESS = "GetReturnStudentDetails_SUCCESS";
export const GetReturnStudentDetails_FAIL = "GetReturnStudentDetails_FAIL";

export const ManageBoarderLeaveDashboard_START = "ManageBoarderLeaveDashboard_START";
export const ManageBoarderLeaveDashboard_SUCCESS = "ManageBoarderLeaveDashboard_SUCCESS";
export const ManageBoarderLeaveDashboard_FAIL = "ManageBoarderLeaveDashboard_FAIL";

export const DepartStuLeaveChange_START = "DepartStuLeaveChange_START";
export const DepartStuLeaveChange_SUCCESS = "DepartStuLeaveChange_SUCCESS";
export const DepartStuLeaveChange_FAIL = "DepartStuLeaveChange_FAIL";

export const ReturnStuLeaveChange_START = "ReturnStuLeaveChange_START";
export const ReturnStuLeaveChange_SUCCESS = "ReturnStuLeaveChange_SUCCESS";
export const ReturnStuLeaveChange_FAIL = "ReturnStuLeaveChange_FAIL";

export const GetAllManagersByRoleId_START = "GetAllManagersByRoleId_START";
export const GetAllManagersByRoleId_SUCCESS = "GetAllManagersByRoleId_SUCCESS";
export const GetAllManagersByRoleId_FAIL = "GetAllManagersByRoleId_FAIL";


export const DeleteRole_START = "DeleteRole_START";
export const DeleteRole_SUCCESS = "DeleteRole_SUCCESS";
export const DeleteRole_FAIL = "DeleteRole_FAIL";


export const ReorderRoles_START = "ReorderRoles_START";
export const ReorderRoles_SUCCESS = "ReorderRoles_SUCCESS";
export const ReorderRoles_FAIL = "ReorderRoles_FAIL";

export const UpdateSchoolUserPassword_START = "UpdateSchoolUserPassword_START";
export const UpdateSchoolUserPassword_SUCCESS = "UpdateSchoolUserPassword_SUCCESS";
export const UpdateSchoolUserPassword_FAIL = "UpdateSchoolUserPassword_FAIL";

export const ResendStudentAndParentsPassword_START = "ResendStudentAndParentsPassword_START";
export const ResendStudentAndParentsPassword_SUCCESS = "ResendStudentAndParentsPassword_SUCCESS";
export const ResendStudentAndParentsPassword_FAIL = "ResendStudentAndParentsPassword_FAIL";


export const UpdateSchoolSubscription_START = "UpdateSchoolSubscription_START";
export const UpdateSchoolSubscription_SUCCESS = "UpdateSchoolSubscription_SUCCESS";
export const UpdateSchoolSubscription_FAIL = "UpdateSchoolSubscription_FAIL";


export const HistoryOfLeaves_START = "HistoryOfLeaves_START";
export const HistoryOfLeaves_SUCCESS = "HistoryOfLeaves_SUCCESS";
export const HistoryOfLeaves_FAIL = "HistoryOfLeaves_FAIL";



export const GetAllStudentsForAdhocLeave_START = "GetAllStudentsForAdhocLeave_START";
export const GetAllStudentsForAdhocLeave_SUCCESS = "GetAllStudentsForAdhocLeave_SUCCESS";
export const GetAllStudentsForAdhocLeave_FAIL = "GetAllStudentsForAdhocLeave_FAIL";

export const GetAllAdhocLeaveStudentDetails_START = "GetAllAdhocLeaveStudentDetails_START";
export const GetAllAdhocLeaveStudentDetails_SUCCESS = "GetAllAdhocLeaveStudentDetails_SUCCESS";
export const GetAllAdhocLeaveStudentDetails_FAIL = "GetAllAdhocLeaveStudentDetails_FAIL";

export const CreateAdhocLeave_START = "CreateAdhocLeave_START";
export const CreateAdhocLeave_SUCCESS = "CreateAdhocLeave_SUCCESS";
export const CreateAdhocLeave_FAIL = "CreateAdhocLeave_FAIL";

export const GetAdhocLeaveDetailById_START = "GetAdhocLeaveDetailById_START";
export const GetAdhocLeaveDetailById_SUCCESS = "GetAdhocLeaveDetailById_SUCCESS";
export const GetAdhocLeaveDetailById_FAIL = "GetAdhocLeaveDetailById_FAIL";


export const DeleteActivity_START = "DeleteActivity_START";
export const DeleteActivity_SUCCESS = "DeleteActivity_SUCCESS";
export const DeleteActivity_FAIL = "DeleteActivity_FAIL";


export const UpdateActivityStatus_START = "UpdateActivityStatus_START";
export const UpdateActivityStatus_SUCCESS = "UpdateActivityStatus_SUCCESS";
export const UpdateActivityStatus_FAIL = "UpdateActivityStatus_FAIL";


export const UpdateActivity_START = "UpdateActivity_START";
export const UpdateActivity_SUCCESS = "UpdateActivity_SUCCESS";
export const UpdateActivity_FAIL = "UpdateActivity_FAIL";

export const EditAdhocLeave_START = "EditAdhocLeave_START";
export const EditAdhocLeave_SUCCESS = "EditAdhocLeave_SUCCESS";
export const EditAdhocLeave_FAIL = "EditAdhocLeave_FAIL";

export const UpdateAdhocLeaveTimeSchedule_START = "UpdateAdhocLeaveTimeSchedule_START";
export const UpdateAdhocLeaveTimeSchedule_SUCCESS = "UpdateAdhocLeaveTimeSchedule_SUCCESS";
export const UpdateAdhocLeaveTimeSchedule_FAIL = "UpdateAdhocLeaveTimeSchedule_FAIL";

export const DeleteAdhocLeaveById_START = "DeleteAdhocLeaveById_START";
export const DeleteAdhocLeaveById_SUCCESS = "DeleteAdhocLeaveById_SUCCESS";
export const DeleteAdhocLeaveById_FAIL = "DeleteAdhocLeaveById_FAIL";

export const GetAllAdhocLeaveforSignOrSignOut_START = "GetAllAdhocLeaveforSignOrSignOut_START";
export const GetAllAdhocLeaveforSignOrSignOut_SUCCESS = "GetAllAdhocLeaveforSignOrSignOut_SUCCESS";
export const GetAllAdhocLeaveforSignOrSignOut_FAIL = "GetAllAdhocLeaveforSignOrSignOut_FAIL";


export const UpdateAdhocLeaveSignInOrSignOut_START = "UpdateAdhocLeaveSignInOrSignOut_START";
export const UpdateAdhocLeaveSignInOrSignOut_SUCCESS = "UpdateAdhocLeaveSignInOrSignOut_SUCCESS";
export const UpdateAdhocLeaveSignInOrSignOut_FAIL = "UpdateAdhocLeaveSignInOrSignOut_FAIL";



export const GetAllCountriesForImportStudents_START = "GetAllCountriesForImportStudents_START";
export const GetAllCountriesForImportStudents_SUCCESS = "GetAllCountriesForImportStudents_SUCCESS";
export const GetAllCountriesForImportStudents_FAIL = "GetAllCountriesForImportStudents_FAIL";



export const SnapshotReturnStuLeave_START = "SnapshotReturnStuLeave_START";
export const SnapshotReturnStuLeave_SUCCESS = "SnapshotReturnStuLeave_SUCCESS";
export const SnapshotReturnStuLeave_FAIL = "SnapshotReturnStuLeave_FAIL";

export const CreateHostResposibilties_START = "CreateHostResposibilties_START";
export const CreateHostResposibilties_SUCCESS = "CreateHostResposibilties_SUCCESS";
export const CreateHostResposibilties_FAIL = "CreateHostResposibilties_FAIL";


export const GetHostResposibilties_START = "GetHostResposibilties_START";
export const GetHostResposibilties_SUCCESS = "GetHostResposibilties_SUCCESS";
export const GetHostResposibilties_FAIL = "GetHostResposibilties_FAIL";

export const BssHostReport_START = "BssHostReport_START";
export const BssHostReport_SUCCESS = "BssHostReport_SUCCESS";
export const BssHostReport_FAIL = "BssHostReport_FAIL";


export const ListofApprovedLeaves_START = "ListofApprovedLeaves_START";
export const ListofApprovedLeaves_SUCCESS = "ListofApprovedLeaves_SUCCESS";
export const ListofApprovedLeaves_FAIL = "ListofApprovedLeaves_FAIL";



export const NonReturnNotificationReport_START = "NonReturnNotificationReport_START";
export const NonReturnNotificationReport_SUCCESS = "NonReturnNotificationReport_SUCCESS";
export const NonReturnNotificationReport_FAIL = "NonReturnNotificationReport_FAIL";

export const StudentDueBackReport_START = "StudentDueBackReport_START";
export const StudentDueBackReport_SUCCESS = "StudentDueBackReport_SUCCESS";
export const StudentDueBackReport_FAIL = "StudentDueBackReport_FAIL";

export const StudentDepartureReport_START = "StudentDepartureReport_START";
export const StudentDepartureReport_SUCCESS = "StudentDepartureReport_SUCCESS";
export const StudentDepartureReport_FAIL = "StudentDepartureReport_FAIL";




export const BoardersLeaveHistoryReport_START = "BoardersLeaveHistoryReport_START";
export const BoardersLeaveHistoryReport_SUCCESS = "BoardersLeaveHistoryReport_SUCCESS";
export const BoardersLeaveHistoryReport_FAIL = "BoardersLeaveHistoryReport_FAIL";


export const AdhocReport_START = "AdhocReport_START";
export const AdhocReport_SUCCESS = "AdhocReport_SUCCESS";
export const AdhocReport_FAIL = "AdhocReport_FAIL";



export const AdhocHistoryReport_START = "AdhocHistoryReport_START";
export const AdhocHistoryReport_SUCCESS = "AdhocHistoryReport_SUCCESS";
export const AdhocHistoryReport_FAIL = "AdhocHistoryReport_FAIL";




export const StudentsAllergicReport_START = "StudentsAllergicReport_START";
export const StudentsAllergicReport_SUCCESS = "StudentsAllergicReport_SUCCESS";
export const StudentsAllergicReport_FAIL = "StudentsAllergicReport_FAIL";




export const BssStudentHostReport_START = "BssStudentHostReport_START";
export const BssStudentHostReport_SUCCESS = "BssStudentHostReport_SUCCESS";
export const BssStudentHostReport_FAIL = "BssStudentHostReport_FAIL";

export const HostConnectedToStudentsReport_START = "HostConnectedToStudentsReport_START";
export const HostConnectedToStudentsReport_SUCCESS = "HostConnectedToStudentsReport_SUCCESS";
export const HostConnectedToStudentsReport_FAIL = "HostConnectedToStudentsReport_FAIL";



export const ExportMedicalIssuedStudentsPdf_START = "ExportMedicalIssuedStudentsPdf_START";
export const ExportMedicalIssuedStudentsPdf_SUCCESS = "ExportMedicalIssuedStudentsPdf_SUCCESS";
export const ExportMedicalIssuedStudentsPdf_FAIL = "ExportMedicalIssuedStudentsPdf_FAIL";


export const GetAllAdhocLeaveByStudentId_START = "GetAllAdhocLeaveByStudentId_START";
export const GetAllAdhocLeaveByStudentId_SUCCESS = "GetAllAdhocLeaveByStudentId_SUCCESS";
export const GetAllAdhocLeaveByStudentId_FAIL = "GetAllAdhocLeaveByStudentId_FAIL";

export const GetCateringReport_START = "GetCateringReport_START";
export const GetCateringReport_SUCCESS = "GetCateringReport_SUCCESS";
export const GetCateringReport_FAIL = "GetCateringReport_FAIL";

export const GetCateringReportDetailsByMealType_START = "GetCateringReportDetailsByMealType_START";
export const GetCateringReportDetailsByMealType_SUCCESS = "GetCateringReportDetailsByMealType_SUCCESS";
export const GetCateringReportDetailsByMealType_FAIL = "GetCateringReportDetailsByMealType_FAIL";
