import React, { useEffect, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../../actions/index";
import { connect, useDispatch } from "react-redux";




import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
export default function AllergicReports(props) {
    const dispatch = useDispatch();
    const [allergy_data, setAllergyData] = useState([])

    const GetAllergicStudentDetailById = async () => {
        dispatch(userActions.GetAllergicStudentDetailById(props.user_uuid)).then((response) => {
            if (response.success === true) {
                const allergy_details = response.data.allergy_details;
                setAllergyData(allergy_details)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    useEffect(() => {
        GetAllergicStudentDetailById()
    }, [dispatch])


    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                {/* <div className="container-fluid pb-6 d-flex align-items-center">
                                    <div id="topaccountmenudata" className="cursor-pointer symbol symbol-30px symbol-md-40px topaccountmenudata " data-kt-menu-placement="bottom-end">
                                        <img alt="Logo" 
                                            src={
                                                props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                : (props?.studentData?.gender === "male" 
                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                            }
                                        />
                                    </div>
                                    <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                </div> */}
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                                <div style={{display:"flex"}}>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                            Student Profile
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" aria-controls="process" aria-selected="true">
                                                            Approved Hosts
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Leave History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Attendance History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Pastoral Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Medical Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link active" id="AllergyReport-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Allergy Reports
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                        AdhocLeave
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                            Adhoc Actual
                                                        </Link>
                                                    </li>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                                    <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                        <img alt="Logo" style={{marginBottom:"4px"}} 
                                                            src={
                                                                props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                                ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                                : (props?.studentData?.gender === "male" 
                                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="AllergyReport" role="tabpanel" aria-labelledby="AllergyReport-tab">
                                                    <div className="card mb-5 tabs-outer">
                                                        <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                            <h5 className="card-title m-0">Allergic Reports</h5>
                                                        </div>
                                                        <div className="card-body position-relative">
                                                        <div className="row gap-3 wrap-Allergic">   
                                                                    <div className="col-md-12">
                                                            {allergy_data.map((item) => {
                                                                return (
                                                               
                                                                    <Card sx={{ mb: 0 }}>
                                                                        <CardContent>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <div className="row  mb-2">
                                                                                                <div className="col-md-4">  <label className="fw-bold">Allergy Name</label></div>
                                                                                                <div className="col-md-8">  <input
                                                                                                className="simple-text"
                                                                                                value={item.allergy_name}
                                                                                                readOnly
                                                                                            />
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                        <div className="row  mb-2">
                                                                                                <div className="col-md-4"><label className="fw-bold">Allergy Info</label></div>
                                                                                                <div className="col-md-8">   <input
                                                                                                className="simple-text"
                                                                                                value={item.allergy_info}
                                                                                                readOnly
                                                                                            />
                                                                                            </div>
                                                                                            </div>
                                                                                       
                                                                                           
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-2">
                                                                                        <div className="col-lg-12 fv-row">
                                                                                        <div className="row">
                                                                                                <div className="col-md-4">  <label className="fw-bold">Additional Notes</label></div>
                                                                                                <div className="col-md-8"> 
                                                                                                {item.allergy_note === "" || item.allergy_note === null  ? <input
                                                                                                className=" text-gray simple-text"
                                                                                                value="No Additional Notes"
                                                                                                readOnly
                                                                                            /> :
                                                                                                <textarea
                                                                                                    rows="2"
                                                                                                    className=" notes-main simple-text"
                                                                                                    value={item.allergy_note}
                                                                                                    readOnly
                                                                                                />
                                                                                            }
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                         
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                                    Word of the Day
                                                                                </Typography>
                                                                                <Typography variant="h5" component="div">
                                                                                   
                                                                                </Typography>
                                                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                                                   
                                                                                </Typography> */}
                                                                        </CardContent>
                                                                    </Card>
                                                                  
                                                                )
                                                            })
                                                            }
                                                            {console.log("allergy_data===========", allergy_data)}
                                                        </div>
                                                    </div>
                                                    </div>    
                                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
