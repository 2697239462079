import React from "react";
import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const ApproveLeaveReport = (props) => {
    const [departureReport, setDepartureReport] = useState([])
    const [isCsv, setIsCsv] = useState();
    const [pdf_url, setPdf_url] = useState("")
    const [count, setCount] = useState()


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState();
    const [order, setOrder] = useState();
    const [class_id, setclass_id] = useState("");
    const [dormitory_id, setdormitory_id] = useState("");
    const [gender, setGender] = useState('');
    const [search, setSearch] = useState("");
    const [to_date, setToDate] = useState("");
    const [from_date, setFromDate] = useState("");

    const [dormitory_ids, setdormitory_ids] = useState([]);
    const [class_ids, setclass_ids] = useState([]);
    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 },
    ]

    useEffect(() => {
        StudentDepartureReport()
    }, [page, limit, sort, order, class_id, dormitory_id, gender, to_date, from_date, search])

    const StudentDepartureReport = () => {
        props.onStudentDepartureReport(page, limit, sort, order, class_id, dormitory_id, gender, to_date, from_date, search).then((response) => {
            if (response.success === true) {
                setDepartureReport(response.data)
                setCount(response.totalCount)
                setPdf_url(response.file)
                setIsCsv(response.csvFile)
            } else {
            }
        });
    }



    const handleChange = (event, value) => {
        setPage(value);
    };

    const changeLimit = (e) => {
        setLimit(parseInt(e.value));
    };

    const DownloadPdf = () => {
        console.log("pdf_url", pdf_url);
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    const DownloadCsvFile = () => {
        const link = document.createElement("a");
        link.href = isCsv;
        // link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }



    useEffect(() => {
        GetAllClassesList();
        GetAllDormitoryStudent();
    }, []);

    const GetAllClassesList = async () => {
        props.onGetAllClassesList().then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let state_data = [];
                state_data.push({
                    label: "Select Year",
                    value: "",
                })
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                    })
                }
                setclass_ids(state_data);
            }
        });
    };

    const GetAllDormitoryStudent = async () => {
        props.onGetAllDormitoryStudent().then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Dormitory",
                    value: "",
                });
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_data.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
                setdormitory_ids(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    };




    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-header align-items-center'>
                                                        <h3 className='card-title'>Student Search</h3>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='row  gap-y-3'>
                                                                    <div className='col-3'>
                                                                        <label className="fw-bold mb-3">Select Year</label>
                                                                        {console.log("customStyles", class_ids)}
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={class_ids}
                                                                            value={class_ids.find((option) => {
                                                                                return option.value === class_id;
                                                                            })}
                                                                            onChange={(event) => {
                                                                                setclass_id(event.value);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <label className="fw-bold mb-3">Select Dormitory</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={dormitory_ids}
                                                                            value={dormitory_ids.find((option) => {
                                                                                return option.value === dormitory_id;
                                                                            })}
                                                                            onChange={(event) => {
                                                                                setdormitory_id(event.value);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <label className="me-3 form-label">From Date</label>
                                                                        <div className='position-relative'>
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                <i class="far fa-calendar-alt"></i>
                                                                            </span>
                                                                            <input
                                                                                id="session-date"
                                                                                type="date"
                                                                                className="form-control bg-transparent"
                                                                                placeholder='Date'
                                                                                value={from_date}
                                                                                onChange={(e) => setFromDate(e.target.value)}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <label className="ms-3 me-3 form-label">To Date</label>
                                                                        <div className='position-relative'>
                                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                <i class="far fa-calendar-alt"></i>
                                                                            </span>
                                                                            <input
                                                                                id="session-date"
                                                                                type="date"
                                                                                className="form-control bg-transparent"
                                                                                placeholder='to'
                                                                                value={to_date}
                                                                                onChange={(e) => setToDate(e.target.value)}
                                                                                min={from_date}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className='form-group'>
                                                                            <label className="fw-bold mb-3">Student Name</label>
                                                                            <div className="position-relative">

                                                                                <div className='position-relative'>
                                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect opacity="1" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="#999"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="#999"></path></svg>
                                                                                    </span>
                                                                                    <input
                                                                                        id="session-date"
                                                                                        type="search"
                                                                                        className="form-control bg-transparent"
                                                                                        placeholder="Search..."
                                                                                        value={search}
                                                                                        onChange={(e) => setSearch(e.target.value)}
                                                                                        required
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <label className="fw-bold mb-3">Gender</label>
                                                                        <div className="d-flex mt-2 radio-btn">
                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gende"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="male"
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "male" ? true : false}
                                                                                />
                                                                                Male
                                                                            </label>
                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gender"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="female"
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "female" ? true : false}
                                                                                />
                                                                                Female
                                                                            </label>

                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gender"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value=""
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "" ? true : false}
                                                                                />
                                                                                Both
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                  
                                                                    <div className='col-6 text-right'>
                                                                        <div className='ml-auto'>
                                                                            <button type="reset" className="btn btn-secondary btn-sm float-end mt-7"
                                                                                onClick={(event) => {
                                                                                    setclass_id("");
                                                                                    setdormitory_id("");
                                                                                    setSearch("");
                                                                                    setGender("");
                                                                                    setToDate("")
                                                                                    setFromDate("")
                                                                                }} >Clear Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mt-5">
                                            <div className="col-sm-12">
                                                <div className="card  tabs-outer">
                                                    <div className="card-header">
                                                        <h2 className="card-title">Departure Report</h2>
                                                        <div className="d-flex align-items-center">
                                                            <Tooltip title="Download .pdf" placement="top">
                                                                <IconButton onClick={() => DownloadPdf()}>
                                                                    <i class="fas fa-file-pdf"></i>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Download .csv" placement="top">
                                                                <IconButton onClick={() => DownloadCsvFile()}>
                                                                    <i class="fas fa-file-csv"></i>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="card-toolbar">
                                                            <div className="table-responsive">
                                                            <MDBTable className='table-row-bordered'>
                                                                <MDBTableHead>
                                                                    <tr>
                                                                        <th>S.No.</th>
                                                                        <th>Student Name</th>
                                                                        <th>Year</th>
                                                                        <th>Dormitory</th>
                                                                        <th>Manager</th>
                                                                        <th>Host Name</th>
                                                                        <th>Host Contact</th>
                                                                        <th>Student Contact</th>
                                                                        <th>Departure Travel Mode</th>
                                                                        <th>Expected Departure Date</th>
                                                                        <th>Expected Departure Time</th>
                                                                        <th>Actual Departure Date</th>
                                                                        <th>Actual Departure Time</th>
                                                                    </tr>
                                                                </MDBTableHead>
                                                                <MDBTableBody>
                                                                    {console.log("reports", departureReport)}
                                                                    {departureReport.map((report, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{report.leave_stu_data.student_name}</td>
                                                                                <td>{report.leave_stu_data.stu_class_data.class_name}</td>
                                                                                <td>{report.leave_stu_data.dormitory_data.dormitory_name}</td>
                                                                                <td>{report.approved_by_manager.first_name} {report.approved_by_manager.last_name}</td>
                                                                                <td>{report?.stu_host_data === null ? report.parent_type : report?.stu_host_data?.host_name}</td>
                                                                                <td>{report?.stu_host_data?.host_contact}</td>
                                                                                <td>{report?.leave_stu_data?.student_phone}</td>
                                                                                <td>{report?.departure_students_data?.planned_depart_mode?.travel_mode_name}</td>
                                                                                <td>{report.departure_students_data.planned_depart_date}</td>
                                                                                <td>{report.departure_students_data.planned_depart_time}</td>
                                                                                <td>{report.departure_date}</td>
                                                                                <td>{report.departure_time}</td>
                                                                            </tr>
                                                                        )
                                                                    })

                                                                    }

                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <Select
                                                                menuPlacement="top"
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                defaultValue={row_limit[1]}
                                                                name="color"
                                                                options={row_limit}
                                                                onChange={changeLimit}
                                                            />
                                                            <Stack spacing={1} >
                                                                <Pagination
                                                                    count={Math.ceil(count / limit)}
                                                                    page={page}
                                                                    onChange={handleChange}
                                                                    color="primary"
                                                                />
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onStudentDepartureReport: userActions.StudentDepartureReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveLeaveReport);