import React, { useCallback } from "react";
import Select from "react-select";
import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ImageCam from "./ImageCam";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Tooltip from '@mui/material/Tooltip';
// import CropImg from "./ImgCrop/CropImg";
import Moment from "moment";
import Webcam from "react-webcam";
import { borderRadius } from "@mui/system";
import ReactCrop from 'react-easy-crop';
import Picture from "./Picture";
import AvatarImageCropper from 'react-avatar-image-cropper';
import { el } from "date-fns/locale";
import ReactDatePicker from "react-datepicker";



import TextField from '@mui/material/TextField';
// import AdapterJalali from '@date-io/date-fns-jalali';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { CropImg } from './ImageCrop';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
// import EasyCrop from "./EasyCrop";
// import { createObjectURL, base64StringToBlob } from "blob-util";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
class AddStudent extends React.Component {

    constructor(props) {

        super(props)
        window.addEventListener('keydown', this.handleKeyPress);
        this.webcamRef = React.createRef();
        this.videoRef = null;


        this.constraints = { facingMode: 'environment', "video": { width: { exact: 400 } }, advanced: [{ focusMode: "continuous" }] };
        this.imageTag = document.getElementById('image-tag');
        this.videoTag = document.getElementById('video-tag');
        this.imageCapturer = null;


        this.state = {
            school_domain: "",
            OpenImageModal: false,
            // crop: {
            //     unit: '%',
            //     width: 30,
            //     aspect: 1 / 1,
            // },
            showEasyCrop: false,
            selectedFile: null,
            croppedImage: null,
            video: null,
            cam_Start: null,
            imageSrc: "",
            hiddenpassword: true,
            add_student_form: {
                student_first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                preferred_name: {
                    type: "text",
                    label: "Preferred Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_email: {
                    type: "email",
                    label: "Student E-mail address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                class_id: {
                    type: "select",
                    label: "Select Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                class_name: {
                    type: "text",
                    label: "Select class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                campus_name: {
                    type: "text",
                    label: "Campus",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                dormitory_id_name: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                sporting_house: {
                    type: "text",
                    label: "Sporting House",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                laundry_number: {
                    type: "text",
                    label: "Laundry Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                tutor_name: {
                    type: "text",
                    label: "PC or Tutor or Carer",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                tutor_email: {
                    type: "email",
                    label: "E-mail address(PC or Tutor or Carer) ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },

                date_of_birth: {
                    type: "date",
                    label: "Date Of Birth *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_age: {
                    type: "text",
                    label: "Age",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                kiosk_mode_pin: {
                    type: "number",
                    label: "Kiosk Mode PIN *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, max_length: 6, number: true },
                },

                student_phone: {
                    type: "text",
                    label: "Student's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                salutation: {
                    type: "text",
                    label: "Salutation",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_email: {
                    type: "email",
                    label: "Father's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },

                mother_email: {
                    type: "email",
                    label: "Mother's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },

                father_name: {
                    type: "text",
                    label: "Father's Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                parent_id: {
                    type: "hidden",
                    label: "Parent Id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_name: {
                    type: "text",
                    label: "Mother's Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                father_phone: {
                    type: "text",
                    label: "Father's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                mother_phone: {
                    type: "text",
                    label: "Mother's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                father_home_phone: {
                    type: "text",
                    label: "Home phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_work_phone: {
                    type: "text",
                    label: "Work phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_home_phone: {
                    type: "text",
                    label: "Home phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_work_phone: {
                    type: "text",
                    label: "Work phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                first_point_contact: {
                    type: "select",
                    label: "First Point of Contact(Number) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Number)", value: "" },
                        { label: "Father's Contact Number", value: "f" },
                        { label: "Mother's Contact Number", value: "m" },
                    ],
                },

                first_point_email: {
                    type: "select",
                    label: "First Point of Contact(Email) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Email)", value: "" },
                        { label: "Father's Email Address", value: "f" },
                        { label: "Mother's Email Address", value: "m" },
                    ],
                },

                show_parent_detail_app: {
                    type: "checkbox",
                    label: "Show Parent Details on Liberty App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                // exclude_automatic_host: {
                //     type: "checkbox",
                //     label: "Exclude as automatic Host ",
                //     value: false,
                //     valid: true,
                //     error_msg: "",
                //     validations: { required: false },
                // },
                // exclude_father_as_host: {
                //     type: "checkbox",
                //     label: "Exclude Father as Host ",
                //     value: false,
                //     valid: true,
                //     error_msg: "",
                //     validations: { required: false },
                // },
                // exclude_mother_as_host: {
                //     type: "checkbox",
                //     label: "Exclude Mother as Host ",
                //     value: false,
                //     valid: true,
                //     error_msg: "",
                //     validations: { required: false },
                // },

                allergy_unauth_access: {
                    type: "checkbox",
                    label: "Allow Unauthenticated Access to student Allergy or Emergency details ",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                withdraw_leave: {
                    type: "checkbox",
                    label: "Withdraw Ad-Hoc Leave",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                permitted_student_app_signout: {
                    type: "checkbox",
                    label: "Permitted to Sign Out using Student App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                display_image_required: {
                    type: "radio",
                    label: "Want to display Image? * ",
                    value: "false",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_allergy_status: {
                    type: "radio",
                    label: "Allergic *",
                    value: "no",
                    valid: true,
                    status: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                school_name: {
                    type: "hidden",
                    label: "School Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                gender: {
                    type: "radio",
                    label: "Gender *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },

                school_detail_id: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                data_status: {
                    type: "hidden",
                    label: "data_status",
                    value: "null",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },



            },

            host_data: {
                host_id: {
                    type: "hidden",
                    // label: "Host Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: false },
                },
                host_name: {
                    type: "text",
                    label: "Host Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                host_relation: {
                    type: "text",
                    label: "Host Relation *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                host_email: {
                    type: "email",
                    label: "Host's Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                host_contact: {
                    type: "text",
                    label: "Host's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                host_address: {
                    type: "text",
                    label: "Host's Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                is_host_approved: {
                    type: "checkbox",
                    label: "Automatically approved as Host",
                    value: true,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                remark_parents: {
                    type: "text",
                    label: "Parent's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                remark_boarding: {
                    type: "text",
                    label: "Boarding's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                remark_host: {
                    type: "text",
                    label: "Host's remark",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                data_status: {
                    type: "hidden",
                    label: "data_status",
                    value: "null",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                host_matched: {
                    type: "text",
                    // label: "Host Name *",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: false },
                },
            },


            allergy_data: [{
                id: 0,
                allergy_name: "",
                allergy_info: ""
            }],

            parent_address_data: [{
                parent_address_type: {
                    type: "text",
                    label: "",
                    value: "father",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                address_line1: {
                    type: "text",
                    label: "Address Line 1",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                address_line2: {
                    type: "text",
                    label: "Address Line 2",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                parent_city: {
                    type: "text",
                    label: "City",
                    value: "",
                    valid: true,
                    options: [],
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_state: {
                    type: "text",
                    label: "State",
                    value: "",
                    valid: true,
                    options: [],
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_postcode: {
                    type: "text",
                    label: "Post Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_country: {
                    type: "text",
                    label: "Country *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    country_code: "",
                    validations: { required: false },
                },
            },
            {
                address_line1: {
                    type: "text",
                    label: "Address Line 1",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                address_line2: {
                    type: "text",
                    label: "Address Line 2",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_city: {
                    type: "text",
                    label: "City",
                    value: "",
                    valid: true,
                    options: [],
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_state: {
                    type: "text",
                    label: "State",
                    value: "",
                    valid: true,
                    options: [],
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_postcode: {
                    type: "text",
                    label: "Post Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                parent_country: {
                    type: "text",
                    label: "Country *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    country_code: "",
                    placeholder: "",
                    validations: { required: false },
                },
                parent_address_type: {
                    type: "text",
                    label: "Country",
                    value: "mother",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
            },
            ],

            father_as_host: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value:true,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            mother_as_host: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value:true,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            student_age_show: {
                type: "text",
                label: "Age",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                validations: { required: true },
            },
            country_id: {
                type: "select",
                label: "Country *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                // placeholder: "Select weekend day",
                validations: { required: true },
                options: [],
            },
            address_same_as_father: {
                type: "checkbox",
                isChecked: '',
                label: "Show Parent Details on Liberty App",
                value: false,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            father_details: false,
            mother_details: false,

        };
    }


    componentDidMount() {
        this.GetAllDormitoryStudent();
        this.GetAllClassesList();
        this.GetAllSelectedCountries();

        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const startPoint = user_info.email.lastIndexOf("@")
        const email_extansion = user_info.email.slice(startPoint, user_info.email.length);
        this.setState({ school_domain: email_extansion })
        const update_add_student_form = { ...this.state.add_student_form };
        update_add_student_form['first_point_email'].value = ''; // Set value to empty string or default value

        update_add_student_form['country_code'].value = user_info.user_school.country_code;
        this.setState({ add_student_form: update_add_student_form });
    }


    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Year",
                value: "",
            });
            const update_add_student_form = { ...this.state.add_student_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,
                    });
                }
            }
            update_add_student_form['class_id'].options = country_level;
            this.setState({ add_student_form: update_add_student_form });
        });
    }


    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_add_student_form = { ...this.state.add_student_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                        });
                    }
                }
            }
            update_add_student_form['dormitory_id'].options = country_level;
            this.setState({ add_student_form: update_add_student_form });
        });
    }



    ////////////////////////////// Parent address API's ////////////////////////
    ///////////////////////////// for country ///////////////////////

    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_country = { ...this.state.country_id };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                    });
                }
            }
            update_country.options = country_level;
            this.setState({ country_id: update_country });
        });
    }
    ///////////////////////////// for state ///////////////////////

    GetAllStatesByCountryIdForFather = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_parent_address_data = this.state.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }

                }
                update_parent_address_data[0].parent_state.options = state_level;
                this.setState({ parent_address_data: update_parent_address_data })
            }
        });
    }
    GetAllStatesByCountryIdForMother = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_parent_address_data = this.state.parent_address_data
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }

                }
                update_parent_address_data[1].parent_state.options = state_level;
                this.setState({ parent_address_data: update_parent_address_data })
            }
        });
    }

    ///////////////////////////// for city ///////////////////////

    GetAllCitiesByStateIdForFather = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_parent_address_data = this.state.parent_address_data
            console.log("response", response);
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_parent_address_data[0].parent_city.options = City_level;
            this.setState({ parent_address_data: update_parent_address_data })
            console.log("update_add_student_form", update_parent_address_data);

        });
    }

    GetAllCitiesByStateIdForMother = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_parent_address_data = this.state.parent_address_data
            console.log("response", response);
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_parent_address_data[1].parent_city.options = City_level;
            this.setState({ parent_address_data: update_parent_address_data })

        });
    }



    ////////////////////////////// main input change handler /////////////////////////

    inputChangeHandler(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === "father_name") {
            form_element.value = event.target.value;
            this.fahterDetailsRequire(event.target.value)
        } else if (identifier === "mother_name") {
            form_element.value = event.target.value;
            this.mohterDetailsRequire(event.target.value)
        } else {
            form_element.value = event.target.value;
        }
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_add_student_form['school_name'].value = user_info.user_school.school_name;
        update_add_student_form['school_detail_id'].value = user_info.user_school.school_detail_id;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_student_form) {
            is_form_valid = update_add_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            add_student_form: update_add_student_form,
            is_form_valid: is_form_valid,
        });
    }


    inputChangeHandlerParentNames(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === "father_name") {
            form_element.value = event.target.value;
            this.fahterDetailsRequire(event.target.value)
        } else if (identifier === "mother_name") {
            form_element.value = event.target.value;
            this.mohterDetailsRequire(event.target.value)
        } else {
            form_element.value = event.target.value;
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_student_form) {
            is_form_valid = update_add_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            add_student_form: update_add_student_form,
            is_form_valid: is_form_valid,
        });
    }




    fahterDetailsRequire(father_name) {
        if (father_name !== '') {
            const update_add_student_form = { ...this.state.add_student_form };
            const update_parent_address_data = [...this.state.parent_address_data];
            console.log("father_name==============", update_parent_address_data);

            update_add_student_form.father_email.validations.required = true
            update_add_student_form.father_phone.validations.required = true
            update_add_student_form.father_name.validations.required = true

            update_parent_address_data[0].address_line1.validations.required = false
            update_parent_address_data[0].address_line2.validations.required = false
            update_parent_address_data[0].parent_country.validations.required = true
            update_parent_address_data[0].parent_state.validations.required = false
            update_parent_address_data[0].parent_city.validations.required = false
            update_parent_address_data[0].parent_postcode.validations.required = true

            this.setState({ father_details: true })
            console.log("father_name==============", update_parent_address_data);
            this.setState({ parent_address_data: update_parent_address_data })
            this.setState({ add_student_form: update_add_student_form })
        } else if (father_name === '') {
            const update_add_student_form = { ...this.state.add_student_form };
            const update_parent_address_data = [...this.state.parent_address_data];

            update_add_student_form.father_email.validations.required = false
            update_add_student_form.father_phone.validations.required = false
            update_add_student_form.father_name.validations.required = false

            update_parent_address_data[0].address_line1.validations.required = false
            update_parent_address_data[0].address_line2.validations.required = false
            update_parent_address_data[0].parent_country.validations.required = false
            update_parent_address_data[0].parent_state.validations.required = false
            update_parent_address_data[0].parent_city.validations.required = false
            update_parent_address_data[0].parent_postcode.validations.required = false

            this.setState({ father_details: false })
            console.log("father_name==============", update_parent_address_data);
            this.setState({ parent_address_data: update_parent_address_data })
            this.setState({ add_student_form: update_add_student_form })
        }
    }
    mohterDetailsRequire(mother_name) {
        if (mother_name !== '') {
            const update_add_student_form = { ...this.state.add_student_form };
            const update_parent_address_data = [...this.state.parent_address_data];

            update_add_student_form.mother_email.validations.required = true
            update_add_student_form.mother_phone.validations.required = true
            update_add_student_form.mother_name.validations.required = true

            update_parent_address_data[1].address_line1.validations.required = false
            update_parent_address_data[1].address_line2.validations.required = false
            update_parent_address_data[1].parent_country.validations.required = true
            update_parent_address_data[1].parent_state.validations.required = false
            update_parent_address_data[1].parent_city.validations.required = false
            update_parent_address_data[1].parent_postcode.validations.required = true

            // if (this.state.address_same_as_father === false) {
            //     update_parent_address_data[1].address_line1.validations.required = false
            //     update_parent_address_data[1].address_line2.validations.required = false
            //     update_parent_address_data[1].parent_postcode.validations.required = false
            // }

            this.setState({ mother_details: true })
            this.setState({ parent_address_data: update_parent_address_data })
            this.setState({ add_student_form: update_add_student_form })
        } else if (mother_name === '') {
            const update_add_student_form = { ...this.state.add_student_form };
            const update_parent_address_data = [...this.state.parent_address_data];

            update_add_student_form.mother_email.validations.required = false
            update_add_student_form.mother_phone.validations.required = false
            update_add_student_form.mother_name.validations.required = false

            update_parent_address_data[1].address_line1.validations.required = false
            update_parent_address_data[1].address_line2.validations.required = false
            update_parent_address_data[1].parent_country.validations.required = false
            update_parent_address_data[1].parent_state.validations.required = false
            update_parent_address_data[1].parent_city.validations.required = false
            update_parent_address_data[1].parent_postcode.validations.required = false

            // if (this.state.address_same_as_father === false) {
            //     update_parent_address_data[1].address_line1.validations.required = false
            //     update_parent_address_data[1].address_line2.validations.required = false
            //     update_parent_address_data[1].parent_postcode.validations.required = false
            // }

            this.setState({ mother_details: false })
            this.setState({ parent_address_data: update_parent_address_data })
            this.setState({ add_student_form: update_add_student_form })
        }
    }




    /////////////////////// this function is make for student profile ///////////////////////////

    take_photo_by_props = (blob, identifier) => {
        if (identifier === "student_avatar") {
            let url = URL.createObjectURL(blob);
            let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
            const update_add_student_form = { ...this.state.add_student_form };
            const form_element = { ...update_add_student_form[identifier] };
            form_element.thumbnail_image = url;

            form_element.value = file;
            update_add_student_form[identifier] = form_element;
            this.setState({ add_student_form: update_add_student_form });

            this.setState({ cam_Start: null })
        } else {
            this.setState({ cam_Start: null })
        }
    }

    cam_not_available = (value) => {
        this.setState({ cam_Start: value })
        toast.info("camera not found", {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    inputChangeHandlerimage = blob => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form["student_avatar"] };
        form_element.thumbnail_image = url;

        form_element.value = file;
        update_add_student_form["student_avatar"] = form_element;
        this.setState({ add_student_form: update_add_student_form });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };

    ///////////////////////////// special function for student email ////////////////////// 

    studentEmailAddresssHandler(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === "student_email") {
            if (event.nativeEvent.data !== "@") {
                form_element.value = event.target.value.toLowerCase()
                form_element.error_msg = "";
            }
        }
        update_add_student_form[identifier] = form_element;
        this.setState({ add_student_form: update_add_student_form });
    }

    ///////////////////////////// student date of birth function /////////////////////////

    studentDateOfBirth(event, identifier) {
        console.log("studentDateOfBirth", event.target.value);
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === "date_of_birth") {
            const birthDate = new Date(event.target.value)
            const currDate = new Date();
            var ageDiff = currDate.getTime() - birthDate.getTime();
            var daydiff = (ageDiff / 31536000000).toFixed(0)
            if (daydiff <= 3) {
                alert("You must be above 4 years of age to join BSS school")
            } else {
                form_element.value = event.target.value;
            }
        }
        update_add_student_form[identifier] = form_element;
        this.setState({ add_student_form: update_add_student_form });
    }

    /////////////////////////////////////// student kioski pin length validation /////////////

    validatePinLength(event) {
        const { value, key } = event.target;
        if (!/^\d*$/.test(value) && key !== 'Backspace') {
            event.preventDefault();
            event.target.value = '';
        }
        if (value.length >= 6 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    }


    /////////////////////////////////////// parent email handler ///////////////////////////

    parentEmailHandler(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === 'father_email') {
            if (event.target.value !== '') {
                this.GetParentDetailsByFatherEmail(event.target.value);
            }
            form_element.value = event.target.value.toLowerCase();
        }
        else if (identifier === 'mother_email') {
            if (event.target.value !== '') {
                this.GetParentDetailsByMotherEmail(event.target.value);
            }
            form_element.value = event.target.value.toLowerCase();
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_student_form) {
            is_form_valid = update_add_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            add_student_form: update_add_student_form,
            is_form_valid: is_form_valid,
        });
    }

    GetParentDetailsByFatherEmail = async (father_email) => {
        this.props.onGetParentDetailsByFatherEmail(father_email).then((response) => {
            if (response.success === true) {
                let student_data = response.data;
                const update_add_student_form = { ...this.state.add_student_form };
                const update_parent_address_data = [...this.state.parent_address_data]
                update_add_student_form['data_status'].value = JSON.stringify(student_data);
                if (response.data === null) {

                } else {
                    for (let key in update_add_student_form) {
                        let form_element = update_add_student_form[key];
                        if (student_data[key]) {
                            update_add_student_form['father_name'].value = student_data.father_name;
                            update_add_student_form['mother_name'].value = student_data.mother_name;
                            update_add_student_form['father_email'].value = student_data.father_email;
                            update_add_student_form['father_phone'].value = student_data.father_phone;

                            update_add_student_form['father_home_phone'].value = student_data.father_home_phone;
                            update_add_student_form['father_work_phone'].value = student_data.father_work_phone;
                            update_add_student_form['mother_email'].value = student_data.mother_email;
                            update_add_student_form['mother_phone'].value = student_data.mother_phone;

                            update_add_student_form['mother_home_phone'].value = student_data.mother_home_phone;
                            update_add_student_form['mother_work_phone'].value = student_data.mother_work_phone;
                            update_add_student_form['parent_id'].value = student_data.parent_id;

                            update_add_student_form.father_email.validations.required = true
                            update_add_student_form.father_phone.validations.required = true
                            update_add_student_form.father_name.validations.required = true
                            update_parent_address_data[0].parent_country.validations.required = true
                            update_add_student_form.mother_email.validations.required = true
                            update_add_student_form.mother_phone.validations.required = true
                            update_add_student_form.mother_name.validations.required = true


                            this.setState({ add_student_form: update_add_student_form });
                            update_add_student_form[key] = form_element;
                        }
                    }
                    update_parent_address_data.forEach(element => {
                        student_data.parent_address.forEach(ele => {
                            if (element.parent_address_type.value === ele.parent_address_type) {
                                element.address_line1.value = ele.address_line1
                                element.address_line2.value = ele.address_line2
                                element.parent_country.value = ele.parent_country
                                element.parent_state.value = ele.parent_state
                                element.parent_city.value = ele.parent_city
                                element.parent_postcode.value = ele.parent_postcode
                                element.parent_address_type.value = ele.parent_address_type

                            }
                        })
                    });
                }
                this.setState({ add_student_form: update_add_student_form });
                this.setState({ parent_address_data: update_parent_address_data })
            } else {
            }
        });
    }

    GetParentDetailsByMotherEmail = async (mother_email) => {
        this.props.onGetParentDetailsByMotherEmail(mother_email).then((response) => {
            if (response.success === true) {
                let student_data = response.data;
                const update_add_student_form = { ...this.state.add_student_form };
                const update_parent_address_data = [...this.state.parent_address_data]
                update_add_student_form['data_status'].value = JSON.stringify(student_data);
                if (response.data === null) {

                } else {

                    for (let key in update_add_student_form) {
                        let form_element = update_add_student_form[key];
                        if (student_data[key]) {
                            update_add_student_form['father_name'].value = student_data.father_name;
                            update_add_student_form['mother_name'].value = student_data.mother_name;
                            update_add_student_form['father_email'].value = student_data.father_email;
                            update_add_student_form['father_phone'].value = student_data.father_phone;

                            update_add_student_form['father_home_phone'].value = student_data.father_home_phone;
                            update_add_student_form['father_work_phone'].value = student_data.father_work_phone;
                            update_add_student_form['mother_email'].value = student_data.mother_email;
                            update_add_student_form['mother_phone'].value = student_data.mother_phone;

                            update_add_student_form['mother_home_phone'].value = student_data.mother_home_phone;
                            update_add_student_form['mother_work_phone'].value = student_data.mother_work_phone;
                            update_add_student_form['parent_id'] = student_data.parent_id;

                            this.setState({ add_student_form: update_add_student_form });
                            update_add_student_form[key] = form_element;
                        }
                    }
                    update_parent_address_data.forEach(element => {
                        student_data.parent_address.forEach(ele => {
                            if (element.parent_address_type.value === ele.parent_address_type) {
                                element.address_line1.value = ele.address_line1
                                element.address_line2.value = ele.address_line2
                                element.parent_country.value = ele.parent_country
                                element.parent_state.value = ele.parent_state
                                element.parent_city.value = ele.parent_city
                                element.parent_postcode.value = ele.parent_postcode
                                element.parent_address_type.value = ele.parent_address_type

                            }
                        })
                    });
                }
                this.setState({ add_student_form: update_add_student_form });
                this.setState({ parent_address_data: update_parent_address_data })
            } else {
            }
        });
    }




    /////////////// this function for class , dormitory , first point of contact father and mother /////////////////

    selectHandleChange(data, identifier, type) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        if (identifier === 'dormitory_id') {
            update_add_student_form['dormitory_id_name'].value = data.label;
        }if (identifier === 'class_id') {
            update_add_student_form['class_name'].value = data.label;
        }
        form_element.value = data.value;
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_add_student_form[identifier] = form_element;
        this.setState({ add_student_form: update_add_student_form });
    }

    ////////////////////////// handle all phone numbers but not host phone number //////////////////////////////////

    phoneNumberHandler(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };
        form_element.value = event;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_add_student_form['school_name'].value = user_info.user_school.school_name;
        update_add_student_form['school_detail_id'].value = user_info.user_school.school_detail_id;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_student_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_add_student_form) {
            is_form_valid = update_add_student_form[identifier].valid && is_form_valid;
        }
        this.setState({
            add_student_form: update_add_student_form,
            is_form_valid: is_form_valid,
        });

    }

    /////////////////////////////////// parent country handler ///////////////////////////

    selectParentCountry(data, index, identifier) {
        const update_parent_address_data = [...this.state.parent_address_data];
        console.log("update_parent_address_data", data, index, identifier);
        if (!update_parent_address_data[index]) {
            update_parent_address_data[index] = {};
        }
        const update_parents_data = { ...update_parent_address_data[index] }
        if (!update_parents_data[identifier]) {
            update_parents_data[identifier] = {}
        }
        if (identifier === "parent_country") {
            if (index === 0) {
                this.GetAllStatesByCountryIdForFather(data.value)
                // update_parents_data[identifier].value = data.label;
                // update_parents_data[identifier].country_code = data.country_code;

                const form_element = update_parents_data[identifier];
                form_element.value = data.label
                form_element.country_code = data.country_code;
                let validate_element = Validations(form_element);
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_parents_data[identifier] = form_element;
                update_parent_address_data[index] = update_parents_data
            } else {
                this.GetAllStatesByCountryIdForMother(data.value)
                // update_parents_data[identifier].value = data.label;
                // update_parents_data[identifier].country_code = data.country_code;

                const form_element = update_parents_data[identifier];
                form_element.value = data.label
                form_element.country_code = data.country_code;
                let validate_element = Validations(form_element);
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_parents_data[identifier] = form_element;
                update_parent_address_data[index] = update_parents_data
            }
        }
        if (identifier === "parent_state") {
            if (index === 0) {
                this.GetAllCitiesByStateIdForFather(data.value)
                update_parents_data[identifier].value = data.label;
            } else {
                this.GetAllCitiesByStateIdForMother(data.value)
                update_parents_data[identifier].value = data.label;
            }
        }
        if (identifier === "parent_city") {
            if (index === 0) {
                update_parents_data[identifier].value = data.label;
            } else {
                update_parents_data[identifier].value = data.label;
            }
        }
        update_parent_address_data[index] = update_parents_data
        this.setState({ parent_address_data: update_parent_address_data });
    }

    /////////////////////////////// for parents address function /////////////////////////

    parentAddressHandler(event, index, identifier) {
        const update_same_as_father = { ...this.state.address_same_as_father };
        const update_parent_address_data = this.state.parent_address_data;
        if (update_same_as_father.value) {
            update_parent_address_data[0][identifier].value = event.target.value;
            update_parent_address_data[1][identifier].value = event.target.value;

        } else {
            if (!update_parent_address_data[index]) {
                update_parent_address_data[index] = {};
            }
            const update_parents_data = { ...update_parent_address_data[index] }
            if (!update_parents_data[identifier]) {
                update_parents_data[identifier] = {}
            }
            update_parents_data[identifier].value = event.target.value;
            const form_element = update_parents_data[identifier];
            let validate_element = Validations(form_element);
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_parents_data[identifier] = form_element;
            update_parent_address_data[index] = update_parents_data
        }
        this.setState({ parent_address_data: update_parent_address_data });
    }

    //////////////////////////// parent all checkbox handler /////////////////////

    inputParentCheckbox(event, identifier) {
        const update_same_as_father = { ...this.state.address_same_as_father };
        const update_father_as_host = { ...this.state.father_as_host }
        const update_mother_as_host = { ...this.state.mother_as_host }
        const update_parent_address = this.state.parent_address_data
        if (identifier === "address_same_as_father") {
            update_same_as_father.value = event.target.checked;
            update_same_as_father.isChecked = event.target.checked;
            this.setState({ address_same_as_father: update_same_as_father });
            if (update_same_as_father.value) {
                const getcountry_id = this.state.country_id.options.find((country)=> country.label.toLowerCase() ===  update_parent_address[0].parent_country.value.toLowerCase())
                if(getcountry_id !== undefined){
                    this.GetAllStatesByCountryIdForMother(getcountry_id.value)
                }
                const getstate_id = this.state.parent_address_data[0].parent_state.options.find((state)=> state.label.toLowerCase() ===  this.state.parent_address_data[0].parent_state.value.toLowerCase())
                if(getstate_id !== undefined){
                    this.GetAllCitiesByStateIdForMother(getstate_id.value)
                }
                update_parent_address[1].address_line1.value = update_parent_address[0].address_line1.value
                update_parent_address[1].address_line2.value = update_parent_address[0].address_line2.value
                update_parent_address[1].parent_country.value = update_parent_address[0].parent_country.value
                update_parent_address[1].parent_state.value = update_parent_address[0].parent_state.value
                update_parent_address[1].parent_city.value = update_parent_address[0].parent_city.value
                update_parent_address[1].parent_postcode.value = update_parent_address[0].parent_postcode.value
                update_parent_address[1].parent_address_type.value = update_parent_address[0].parent_address_type.value
            }
            this.setState({ parent_address_data: update_parent_address })
        }
        if (identifier === "father_as_host") {
            console.log("event.target.checked", !event.target.checked);
            update_father_as_host.value = !event.target.checked;
            update_father_as_host.isChecked = event.target.checked;
            this.setState({ father_as_host: update_father_as_host });
        }
        if (identifier === "mother_as_host") {
            update_mother_as_host.value = event.target.checked;
            update_mother_as_host.isChecked = !event.target.checked;
            this.setState({ mother_as_host: update_mother_as_host });
        }
    }






















    ////////////////////////////////// get host data by host email and phone number //////////////////////

    GetStudentHostByHostEmail = async (host_email) => {
        this.props.onGetStudentHostByHostEmail(host_email).then((response) => {
            if (response.success === true) {
                let student_data = response.data;
                const update_host_data = { ...this.state.host_data };
                update_host_data['data_status'].value = JSON.stringify(student_data);
                if (student_data === null) {
                } else {
                    for (let key in update_host_data) {
                        let form_element = update_host_data[key];
                        if (student_data[key]) {
                            update_host_data['host_name'].value = student_data.host_name;
                            update_host_data['host_relation'].value = student_data.stu_host.host_relation;
                            update_host_data['host_contact'].value = student_data.host_contact;
                            update_host_data['host_email'].value = student_data.host_email;

                            update_host_data['host_address'].value = student_data.host_address;
                            update_host_data['remark_parents'].value = student_data.remark_parents;
                            update_host_data['remark_boarding'].value = student_data.remark_boarding;
                            update_host_data['remark_host'].value = student_data.remark_host;
                            update_host_data['host_id'].value = student_data.host_id;

                            update_host_data['host_matched'].value = true;

                            this.setState({ host_data: update_host_data });
                            update_host_data[key] = form_element
                        }
                    }
                }
                this.setState({ host_data: update_host_data })
            } else {

            }
        })
    }

    GetStudentHostByHostContact = async (host_contact) => {
        this.props.onGetStudentHostByHostContact(host_contact).then((response) => {
            const update_host_matched = this.state.host_matched;
            const update_host_data = { ...this.state.host_data }
            if (response.success === true) {
                let student_data = response.data;
                update_host_data['data_status'].value = JSON.stringify(student_data);
                if (student_data === null) {

                } else {
                    for (let key in update_host_data) {
                        let form_element = update_host_data[key];
                        if (student_data[key]) {
                            update_host_data['host_name'].value = student_data.host_name;
                            update_host_data['host_contact'].value = student_data.host_contact;
                            update_host_data['host_email'].value = student_data.host_email;

                            update_host_data['host_address'].value = student_data.host_address;
                            update_host_data['remark_parents'].value = student_data.remark_parents;
                            update_host_data['remark_boarding'].value = student_data.remark_boarding;
                            update_host_data['remark_host'].value = student_data.remark_host;
                            update_host_data['host_id'].value = student_data.host_id;

                            this.setState({ host_data: update_host_data });
                            update_host_data[key] = form_element;
                        }
                    }
                    this.setState({
                        host_data: update_host_data,
                        host_matched: false
                    });
                }
            } else {
            }
        });
    }

    //////////////////////////////// host input handler with host phone number /////////////////

    inputChangeHost(event, identifier) {
        const update_host_data = { ...this.state.host_data }
        const form_element = { ...update_host_data[identifier] };
        if (identifier === 'host_contact') {
            this.GetStudentHostByHostContact(event);
            form_element.value = event;
        }

        else if (identifier === 'host_email') {
            this.GetStudentHostByHostEmail(event.target.value);
            form_element.value = event.target.value.toLowerCase();
        } else {
            form_element.value = event.target.value;
        }
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_host_data[identifier] = form_element;
        this.setState({ host_data: update_host_data })
    }

    //////////////////////////////// host checkbox //////////////////////////////

    inputChangehostCheckbox(event) {
        const update_host_data = { ...this.state.host_data };
        const form_element = { ...update_host_data.is_host_approved };
        if (form_element.type === "checkbox") {
            if (form_element.value === true) {
                form_element.value = event.target.checked;
                form_element.value = false
            } else {
                form_element.value = event.target.checked;
                form_element.value = true
            }
        } else {
            form_element.value = event.target.value;
        }
        update_host_data.is_host_approved = form_element;
        this.setState({ host_data: update_host_data });
    }

    ///////////////////////// Allergy function add allergy ,delete Allergy , input handler ///////////////////

    inputChangeAllergyName(event, identifier, value) {
        let setName = this.state.allergy_data
        setName[value].allergy_name = event.target.value
        this.setState({ rowname: setName.allergy_name })
    }

    inputChangeAllergyInfo(event, identifier, value) {
        let setInfo = this.state.allergy_data
        setInfo[value].allergy_info = event.target.value
        this.setState({ rowinfo: setInfo.allergy_info })
    }

    clickAddAllergyrow() {
        const update_row = this.state.allergy_data;
        let counter = update_row.length
        update_row.push(
            {
                id: counter++,
                allergy_name: "",
                allergy_info: ""
            }
        )
        this.setState({ allergy_data: update_row })
    }
    clickDeleteAllergyrow = (index, e) => {
        if (this.state.allergy_data.length > 1) {
            const delete_row = [...this.state.allergy_data];
            this.setState({ allergy_data: delete_row.filter((i) => i !== index) })
            // console.log("delete row", delete_row)
        }
    };

    /////////////////////////////// last card check box handler //////////////////////////////

    inputChangeHandlerCheckbox(event, identifier) {
        const update_add_student_form = { ...this.state.add_student_form };
        const form_element = { ...update_add_student_form[identifier] };

        if (form_element.type === "checkbox") {
            form_element.value = event.target.checked;
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_add_student_form[identifier] = form_element;
        this.setState({ add_student_form: update_add_student_form });
    }





    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_add_student_form = this.state.add_student_form;
        const update_parent_address = this.state.parent_address_data;
        const update_host_data = this.state.host_data;

        // console.log("this.state.add_student_form", this.state.add_student_form)
        console.log("this.state.address_same_as_father", this.state.address_same_as_father.value)
        console.log("update_add_student_form console---------", update_add_student_form)

        for (let key in update_add_student_form) {
            if (key === 'student_email') {
                let form_element = update_add_student_form[key];
                const old_value = form_element.value;
                const email_Obj = form_element;
                email_Obj.value = email_Obj.value + this.state.school_domain;
                let validate_element = Validations(email_Obj);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                form_element.value = old_value;
                update_add_student_form[key] = form_element;
            } else {
                let form_element = update_add_student_form[key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_add_student_form[key] = form_element;
            }
        }

        for (let key in update_host_data) {
            let form_element = update_host_data[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_host_data[key] = form_element;
        }

        for (let index in update_parent_address) {
            let update_father_address = update_parent_address[0]
            let update_mother_address = update_parent_address[1]

            for (let key in update_father_address) {
                let form_element = update_father_address[key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_father_address[key] = form_element;
            }

            for (let key in update_mother_address) {
                let form_element = update_mother_address[key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_mother_address[key] = form_element;
            }
        }
        this.setState({ host_data: update_host_data });
        this.setState({ parent_address_data: update_parent_address });
        this.setState({ add_student_form: update_add_student_form });
        if (this.state.mother_details === false && this.state.father_details === false) {
            toast.error("Please fill either father or mother details to submit the form", {
                position: toast.POSITION.TOP_CENTER,
            });
            return
        }




        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.add_student_form) {
                if (key === 'student_email') {
                    const email = this.state.add_student_form['student_email'].value + this.state.school_domain;
                    form_data[key] = email;
                } else if (key === "date_of_birth") {
                    form_data[key] = moment(this.state.add_student_form['date_of_birth'].value).format('YYYY-MM-DD')
                } else {
                    form_data[key] = this.state.add_student_form[key].value;
                }
            }

            let oldArray = this.state.allergy_data;
            let update_allergy_data = oldArray.map((item) => {
                return ({
                    allergy_name: item.allergy_name,
                    allergy_info: item.allergy_info
                })
            })
            form_data['allergy_data'] = JSON.stringify(update_allergy_data);
            let update_host_matched = this.state.host_data.host_matched.value;

            if (update_host_matched === false) {
                let host_data = {
                    "host_name": this.state.host_data.host_name.value,
                    "host_relation": this.state.host_data.host_relation.value,
                    "host_email": this.state.host_data.host_email.value,
                    "host_contact": this.state.host_data.host_contact.value,
                    "host_address": this.state.host_data.host_address.value,
                    "is_host_approved": this.state.host_data.is_host_approved.value,
                    "remark_parents": this.state.host_data.remark_parents.value,
                    "remark_boarding": this.state.host_data.remark_boarding.value,
                    "remark_host": this.state.host_data.remark_host.value,
                };
                let update_host_id = this.state.host_data.host_id.value;
                if (host_data.host_name) {
                    form_data['host_id'] = update_host_id;
                    form_data['host_data'] = JSON.stringify(host_data);
                }
            } else {
                let host_data = {
                    "host_name": this.state.host_data.host_name.value,
                    "host_relation": this.state.host_data.host_relation.value,
                    "host_email": this.state.host_data.host_email.value,
                    "host_contact": this.state.host_data.host_contact.value,
                    "host_address": this.state.host_data.host_address.value,
                    "is_host_approved": this.state.host_data.is_host_approved.value,
                    "remark_parents": this.state.host_data.remark_parents.value,
                    "remark_boarding": this.state.host_data.remark_boarding.value,
                    "remark_host": this.state.host_data.remark_host.value,
                };
                let update_host_id = this.state.host_data.host_id.value;
                if (host_data.host_name) {
                    form_data['host_id'] = update_host_id;
                    form_data['host_data'] = JSON.stringify(host_data);
                }
            }


            if (this.state.address_same_as_father.value === false) {
                if (this.state.father_details === true && this.state.mother_details === true) {
                    let parent_address_data = [
                        {
                            "address_line1": this.state.parent_address_data[0].address_line1.value,
                            "address_line2": this.state.parent_address_data[0].address_line2.value,
                            "parent_state": this.state.parent_address_data[0].parent_state.value,
                            "parent_city": this.state.parent_address_data[0].parent_city.value,
                            "parent_postcode": this.state.parent_address_data[0].parent_postcode.value,
                            "parent_country": this.state.parent_address_data[0].parent_country.value,
                            "parent_address_type": this.state.parent_address_data[0].parent_address_type.value,

                        },
                        {
                            "address_line1": this.state.parent_address_data[1].address_line1.value,
                            "address_line2": this.state.parent_address_data[1].address_line2.value,
                            "parent_state": this.state.parent_address_data[1].parent_state.value,
                            "parent_city": this.state.parent_address_data[1].parent_city.value,
                            "parent_postcode": this.state.parent_address_data[1].parent_postcode.value,
                            "parent_country": this.state.parent_address_data[1].parent_country.value,
                            "parent_address_type": this.state.parent_address_data[1].parent_address_type.value,

                        }
                    ]

                    form_data['parent_address_data'] = JSON.stringify(parent_address_data);
                } else if (this.state.father_details === true && this.state.mother_details === false) {
                    let parent_address_data = [
                        {
                            "address_line1": this.state.parent_address_data[0].address_line1.value,
                            "address_line2": this.state.parent_address_data[0].address_line2.value,
                            "parent_state": this.state.parent_address_data[0].parent_state.value,
                            "parent_city": this.state.parent_address_data[0].parent_city.value,
                            "parent_postcode": this.state.parent_address_data[0].parent_postcode.value,
                            "parent_country": this.state.parent_address_data[0].parent_country.value,
                            "parent_address_type": this.state.parent_address_data[0].parent_address_type.value,
                        }
                    ]

                    form_data['parent_address_data'] = JSON.stringify(parent_address_data);
                } else if (this.state.father_details === false && this.state.mother_details === true) {
                    let parent_address_data = [
                        {
                            "address_line1": this.state.parent_address_data[1].address_line1.value,
                            "address_line2": this.state.parent_address_data[1].address_line2.value,
                            "parent_state": this.state.parent_address_data[1].parent_state.value,
                            "parent_city": this.state.parent_address_data[1].parent_city.value,
                            "parent_postcode": this.state.parent_address_data[1].parent_postcode.value,
                            "parent_country": this.state.parent_address_data[1].parent_country.value,
                            "parent_address_type": this.state.parent_address_data[1].parent_address_type.value,

                        }
                    ]
                    form_data['parent_address_data'] = JSON.stringify(parent_address_data);
                }
            }

            if (this.state.address_same_as_father.value === true) {
                let parent_address_data = [
                    {
                        "address_line1": this.state.parent_address_data[0].address_line1.value,
                        "address_line2": this.state.parent_address_data[0].address_line2.value,
                        "parent_state": this.state.parent_address_data[0].parent_state.value,
                        "parent_city": this.state.parent_address_data[0].parent_city.value,
                        "parent_postcode": this.state.parent_address_data[0].parent_postcode.value,
                        "parent_country": this.state.parent_address_data[0].parent_country.value,
                        "parent_address_type": this.state.parent_address_data[0].parent_address_type.value,
                    }
                ]
                form_data['parent_address_data'] = JSON.stringify(parent_address_data);
            }

            if (this.state.father_as_host.value === true && this.state.mother_as_host.value === false) {
                let parent_as_host = [{
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === true && this.state.mother_as_host.value === true) {
                let parent_as_host = [{
                    parent_type: "mother",
                    is_host_approved: true,
                    host_relation: "mother as host"
                },
                {
                    parent_type: "father",
                    is_host_approved: true,
                    host_relation: "father as host"
                }]
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            } else if (this.state.father_as_host.value === false && this.state.mother_as_host.value === false) {
                let parent_as_host = []
                form_data['parent_as_host'] = JSON.stringify(parent_as_host);
            }


            console.log("form Data check inside submit", form_data)
            this.props.onCreateStudentAndParents(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/student/student-list`,
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });
        }
    };


    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };



    render() {
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "user"
        };

        const { add_student_form } = this.state;
        const { parent_address_data } = this.state;
        const { student_age_show } = this.state
        const { host_data } = this.state;
        const { country_id } = this.state
        const { father_details } = this.state
        const { mother_details } = this.state
        console.log("mother_details", mother_details)
        console.log("add_student_form=== in render", add_student_form);
        console.log("father_details", father_details);
        // console.log("state father", father_details)
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

        let curr = new Date();
        curr.setDate(curr.getDate());
        let date = curr.toISOString().substring(0, 10);
        let setEnterUserAge = add_student_form.date_of_birth.value;
        let setNew = new Date(setEnterUserAge)
        let d = new Date();
        const currentyear = d.getFullYear();
        const yearOnly = currentyear - setNew.getFullYear() ? currentyear - setNew.getFullYear() : ``;
        let age_now = d.getFullYear() - setNew.getFullYear();
        let monthsDiff = d.getMonth() - setNew.getMonth();
        let dayDiff = d.getDate() - setNew.getDate();
        if (dayDiff < 0) {
            let tempDate = new Date(d.getFullYear(), d.getMonth() - 1, 0);
            dayDiff += tempDate.getDate();
            monthsDiff--;
            // monthsDiff--;
            // dayDiff += new Date(d.getFullYear(), d.getMonth(), 0).getDate();
        }
        if (monthsDiff < 0 || (monthsDiff === 0 && dayDiff < 0)) {
            age_now--;
            monthsDiff += 12;
        }

        let years = age_now > 0 ? age_now + " year" + (age_now > 1 ? "s" : "") : "";
        let months = monthsDiff > 0 ? monthsDiff + " month" + (monthsDiff > 1 ? "s" : "") : "";
        let year = years && months ? years + " " + months : years + months;

        student_age_show.value = year;
        add_student_form.student_age.value = yearOnly;
        return (
            <React.Fragment>

                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" sixe="xl" style={{ color: "#ffffff", }}></i>

                        </span>

                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true} />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold ">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler} autoComplete="off">
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Student Personal Details</h2>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="profile-image-out p-0 p-0">
                                                                                    <div className="row justify-content-center">
                                                                                        <div className="col-sm-12">
                                                                                            <img alt="profile" src={add_student_form.student_avatar.thumbnail_image} className="img-fluid" />
                                                                                            {this.state.cam_Start === null ? ""
                                                                                                :
                                                                                                <div className="pb-5 student-photo-by-camera">
                                                                                                    <Picture
                                                                                                        take_photo_by_props={(blob, identifier) => this.take_photo_by_props(blob, identifier)}
                                                                                                        cam_not_available={(value) => this.cam_not_available(value)}
                                                                                                    />
                                                                                                </div>
                                                                                            }


                                                                                            <div className="h-100  wrap-btn-col align-items-center">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                                    onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                                                >
                                                                                                    Upload Image
                                                                                                </button>
                                                                                                <label className="fw-bold px-5">Or</label>
                                                                                                <button className="btn btn-sm btn-primary apply-btn"
                                                                                                    onClick={() => this.setState({ cam_Start: "stream" })}>
                                                                                                    Take Photo
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_student_form.student_first_name.label} </label>
                                                                                <input
                                                                                    type={add_student_form.student_first_name.type}
                                                                                    value={add_student_form.student_first_name.value ? add_student_form.student_first_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={add_student_form.student_first_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "student_first_name")}
                                                                                    autoComplete="off"
                                                                                    required
                                                                                />
                                                                                {!add_student_form.student_first_name.valid && add_student_form.student_first_name.onBlur_out ? <div className="error field-error">{add_student_form.student_first_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_student_form.student_last_name.label}</label>
                                                                                <input
                                                                                    type={add_student_form.student_last_name.type}
                                                                                    className="form-control"
                                                                                    placeholder={add_student_form.student_last_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "student_last_name")}
                                                                                    autoComplete="off"
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_student_form.preferred_name.label}</label>
                                                                                <input
                                                                                    type={add_student_form.preferred_name.type}
                                                                                    className="form-control"
                                                                                    placeholder={add_student_form.preferred_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "preferred_name")}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {!add_student_form.preferred_name.valid && add_student_form.preferred_name.onBlur_out ? <div className="error field-error">{add_student_form.preferred_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_student_form.date_of_birth.label}</label>
                                                                                <div className='position-relative'>
                                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                                        <i class="far fa-calendar-alt"></i>
                                                                                    </span>
                                                                                    <input
                                                                                        id="session-date"
                                                                                        type={add_student_form.date_of_birth.type}
                                                                                        className="form-control"
                                                                                        placeholder={add_student_form.date_of_birth.placeholder}
                                                                                        value={add_student_form.date_of_birth.value !== "" ? add_student_form.date_of_birth.value : ""}
                                                                                        onChange={(event) => this.studentDateOfBirth(event, "date_of_birth")}
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {!add_student_form.date_of_birth.valid && add_student_form.date_of_birth.onBlur_out ? <div className="error field-error">{add_student_form.date_of_birth.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5" >
                                                                                <label className="fw-bold mb-2">{student_age_show.label}</label>
                                                                                <input
                                                                                    style={{ backgroundColor: "#f5f5f5", pointerEvents: "none" }}
                                                                                    type={student_age_show.type}
                                                                                    className="form-control"
                                                                                    value={student_age_show.value ? student_age_show.value : year}
                                                                                    placeholder={student_age_show.placeholder}
                                                                                    autoComplete="off"
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2 ">
                                                                                    {add_student_form.student_email.label}
                                                                                    <Tooltip title="Note:- If genuine address used, student will automatically receive Login instructions to  “VIEW only” Personal & Leave details." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <div className="d-flex rounded-1" style={{ border: "1px solid #ccccccb8" }}>
                                                                                    <input
                                                                                        style={{ textAlign: "right", paddingRight: "3px", border: "0px" }}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={add_student_form.student_email.value}
                                                                                        placeholder={add_student_form.student_email.placeholder}
                                                                                        onChange={(event) => this.studentEmailAddresssHandler(event, "student_email")}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    <div className="h-100  top-0 fw-bold end-0 border border-start-0 rounded-1" style={{ backgroundColor: "#ecf7ff", padding: "7px 5px 8px 7px" }}>
                                                                                        {this.state.school_domain}
                                                                                    </div>
                                                                                </div>
                                                                                {!add_student_form.student_email.valid && add_student_form.student_email.onBlur_out ? <div className="error field-error">{add_student_form.student_email.error_msg}</div> : ""}
                                                                            </div>


                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_student_form.student_phone.label}</label>
                                                                                <ReactPhoneInput
                                                                                    countryCodeEditable={false}
                                                                                    country={add_student_form.country_code.value ? add_student_form.country_code.value : ""}
                                                                                    value={add_student_form.student_phone.value ? add_student_form.student_phone.value : ""}
                                                                                    onChange={(event) => this.phoneNumberHandler(event, "student_phone")}
                                                                                    enableLongNumbers={true}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {!add_student_form.student_phone.valid && add_student_form.student_phone.onBlur_out ? <div className="error field-error">{add_student_form.student_phone.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Gender * </label>
                                                                                <div className="d-flex">
                                                                                    <label className="cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="Gender"
                                                                                            name="Gender"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="male"
                                                                                            onChange={(event) => this.inputChangeHandler(event, "gender")}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        Male
                                                                                    </label>
                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="Gender1"
                                                                                            name="Gender"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="female"
                                                                                            onChange={(event) => this.inputChangeHandler(event, "gender")}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        Female
                                                                                    </label>
                                                                                </div>
                                                                                {!add_student_form.gender.valid && add_student_form.gender.onBlur_out ? <div className="error field-error">{add_student_form.gender.error_msg}</div> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.class_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={add_student_form.class_id.options}
                                                                            value={add_student_form.class_id.options.filter(function (option) {
                                                                                return option.value === add_student_form.class_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.selectHandleChange(newValue, "class_id")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.class_id.valid && add_student_form.class_id.onBlur_out ? <div className="error field-error">{add_student_form.class_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.dormitory_id.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={add_student_form.dormitory_id.options}
                                                                            value={add_student_form.dormitory_id.options.filter(function (option) {
                                                                                return option.value === add_student_form.dormitory_id.value;
                                                                            })}
                                                                            onChange={(newValue) => this.selectHandleChange(newValue, "dormitory_id")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.dormitory_id.valid && add_student_form.dormitory_id.onBlur_out ? <div className="error field-error">{add_student_form.dormitory_id.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.campus_name.label} </label>
                                                                        <input
                                                                            type={add_student_form.campus_name.type}
                                                                            value={add_student_form.campus_name.value ? add_student_form.campus_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.campus_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "campus_name")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.sporting_house.label} </label>
                                                                        <input
                                                                            type={add_student_form.sporting_house.type}
                                                                            value={add_student_form.sporting_house.value ? add_student_form.sporting_house.value : ""}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.sporting_house.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "sporting_house")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.laundry_number.label} </label>
                                                                        <input
                                                                            type={add_student_form.laundry_number.type}
                                                                            value={add_student_form.laundry_number.value ? add_student_form.laundry_number.value : ""}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.laundry_number.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "laundry_number")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.tutor_name.label} </label>
                                                                        <input
                                                                            type={add_student_form.tutor_name.type}
                                                                            value={add_student_form.tutor_name.value ? add_student_form.tutor_name.value : ""}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.tutor_name.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "tutor_name")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.tutor_email.label}</label>
                                                                        <input
                                                                            type={add_student_form.tutor_email.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.tutor_email.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "tutor_email")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mt-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.kiosk_mode_pin.label}</label>
                                                                        <div className="position-relative">
                                                                            <input
                                                                                type={add_student_form.kiosk_mode_pin.type}
                                                                                className="form-control appearance-none"
                                                                                maxLength={6}
                                                                                min={0}
                                                                                placeholder={add_student_form.kiosk_mode_pin.placeholder}
                                                                                onChange={(event) => this.inputChangeHandler(event, "kiosk_mode_pin")}
                                                                                onKeyDown={(event) => this.validatePinLength(event)}
                                                                                autoComplete="new-password"
                                                                            />
                                                                            {!add_student_form.kiosk_mode_pin.valid && add_student_form.kiosk_mode_pin.onBlur_out ? <div className="error field-error">{add_student_form.kiosk_mode_pin.error_msg}</div> : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <input
                                                            type="hidden"
                                                            value={add_student_form.data_status.value ? add_student_form.data_status.value : ""}
                                                        />






                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Student Parent Details</h2>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.father_name.label}</label>
                                                                        <input
                                                                            type={add_student_form.father_name.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.father_name.placeholder}
                                                                            value={add_student_form.father_name.value ? add_student_form.father_name.value : ""}
                                                                            onChange={(event) => this.inputChangeHandlerParentNames(event, "father_name")}
                                                                            readOnly={add_student_form.data_status.value === "null" ? false : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.father_name.valid && add_student_form.father_name.onBlur_out ? <div className="error field-error">{add_student_form.father_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.mother_name.label}</label>
                                                                        <input
                                                                            type={add_student_form.mother_name.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.mother_name.placeholder}
                                                                            value={add_student_form.mother_name.value ? add_student_form.mother_name.value : ""}
                                                                            onChange={(event) => this.inputChangeHandlerParentNames(event, "mother_name")}
                                                                            readOnly={add_student_form.data_status.value === "null" ? false : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.mother_name.valid && add_student_form.mother_name.onBlur_out ? <div className="error field-error">{add_student_form.mother_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.father_email.label}</label>
                                                                        <input
                                                                            type={add_student_form.father_email.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.father_email.placeholder}
                                                                            onChange={(event) => this.parentEmailHandler(event, "father_email")}
                                                                            value={add_student_form.father_email.value ? add_student_form.father_email.value : ""}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.father_email.valid && add_student_form.father_email.onBlur_out ? <div className="error field-error">{add_student_form.father_email.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.mother_email.label}</label>
                                                                        <input
                                                                            type={add_student_form.mother_email.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.mother_email.placeholder}
                                                                            value={add_student_form.mother_email.value ? add_student_form.mother_email.value : ""}
                                                                            onChange={(event) => this.parentEmailHandler(event, "mother_email")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.mother_email.valid && add_student_form.mother_email.onBlur_out ? <div className="error field-error">{add_student_form.mother_email.error_msg}</div> : ""}
                                                                    </div>


                                                                    {/* <div className="col-lg-12">
                                                                        <div className="d-flex justify-content-between gap-5">
                                                                            <h6 className="mb-0"> Father Address </h6>
                                                                            <div className="d-flex align-items-center">
                                                                                <label className="fw-bold d-flex align-items-center"> <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input me-4"
                                                                                    value={this.state.father_as_host.value}
                                                                                    onChange={(event) => this.inputParentCheckbox(event, "father_as_host")}
                                                                                    autoComplete="off"
                                                                                /> 
                                                                                Automatically Add Father as Host
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div> */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0]?.address_line1?.label}</label>
                                                                        <input
                                                                            type={parent_address_data[0].address_line1.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[0].address_line1.placeholder}
                                                                            value={parent_address_data[0].address_line1.value ? parent_address_data[0].address_line1.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 0, "address_line1")}
                                                                            readOnly={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[0].address_line1.valid && parent_address_data[0].address_line1.onBlur_out ? <div className="error field-error">{parent_address_data[0].address_line1.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0].address_line2.label}</label>
                                                                        <input
                                                                            type={parent_address_data[0].address_line2.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[0].address_line2.placeholder}
                                                                            value={parent_address_data[0].address_line2.value ? parent_address_data[0].address_line2.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 0, "address_line2")}
                                                                            readOnly={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[0].address_line2.valid && parent_address_data[0].address_line2.onBlur_out ? <div className="error field-error">{parent_address_data[0].address_line2.error_msg}</div> : ""}
                                                                    </div>

                                                                    {/* ///////////////////////////// father country , state , city , postal code ,////////////////////////////////////// */}

                                                                    <div className="col-lg-4 col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0].parent_country.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={country_id.options}
                                                                            value={country_id.options.find((option) => option.label === parent_address_data[0].parent_country.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 0, "parent_country")}
                                                                        />
                                                                        {!parent_address_data[0].parent_country.valid && parent_address_data[0].parent_country.onBlur_out ? <div className="error field-error">{parent_address_data[0].parent_country.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0].parent_state.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={parent_address_data[0].parent_state.options}
                                                                            value={parent_address_data[0].parent_state.options.find((option) => option.label === parent_address_data[0].parent_state.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 0, "parent_state")}
                                                                            readOnly={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[1].parent_city.valid && parent_address_data[1].parent_city.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_city.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-2  col-xl fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0].parent_city.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={parent_address_data[0].parent_city.options}
                                                                            value={parent_address_data[0].parent_city.options.find((option) => option.label === parent_address_data[0].parent_city.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 0, "parent_city")}
                                                                            readOnly={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[1].parent_city.valid && parent_address_data[1].parent_city.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_city.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-2  col-xl fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[0].parent_postcode.label}</label>
                                                                        <input
                                                                            type={parent_address_data[0].parent_postcode.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[0].parent_postcode.placeholder}
                                                                            value={parent_address_data[0].parent_postcode.value ? parent_address_data[0].parent_postcode.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 0, "parent_postcode")}
                                                                            readOnly={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[0].parent_postcode.valid && parent_address_data[0].parent_postcode.onBlur_out ? <div className="error field-error">{parent_address_data[0].parent_postcode.error_msg}</div> : ""}
                                                                    </div>

                                                                    {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.father_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[0].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[0].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.father_phone.value ? add_student_form.father_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "father_phone")}
                                                                            disabled={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.father_phone.valid && add_student_form.father_phone.onBlur_out ? <div className="error field-error">{add_student_form.father_phone.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.father_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[0].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[0].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.father_work_phone.value ? add_student_form.father_work_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "father_work_phone")}
                                                                            disabled={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            // disabled={add_student_form.data_status.value === "null" ? false : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.father_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[0].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[0].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.father_home_phone.value ? add_student_form.father_home_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "father_home_phone")}
                                                                            disabled={father_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>





                                                                    <div className="col-lg-12">
                                                                        <div className="d-flex justify-content-between gap-5">
                                                                            <div className="d-flex align-items-center gap-10">
                                                                                <h6 className="mb-0">Mother Address  </h6>
                                                                                <label className="fw-bold d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input me-4"
                                                                                        value={this.state.address_same_as_father.value}
                                                                                        onChange={(event) => this.inputParentCheckbox(event, "address_same_as_father")}
                                                                                        autoComplete="off"
                                                                                        disabled={this.state.father_details === true && this.state.mother_details === true ? false : true}
                                                                                    />
                                                                                    If Mother Address are same as father
                                                                                </label>
                                                                            </div>
{/* 
                                                                            <div className="d-flex align-items-center">
                                                                                <label className="fw-bold d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input me-4"
                                                                                        value={this.state.mother_as_host.value}
                                                                                        onChange={(event) => this.inputParentCheckbox(event, "mother_as_host")}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    Automatically Add Mother as Host
                                                                                </label>
                                                                            </div> */}
                                                                        </div>
                                                                        <hr />
                                                                    </div>












                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].address_line1.label}</label>
                                                                        <input
                                                                            type={parent_address_data[1].address_line1.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[1].address_line1.placeholder}
                                                                            value={parent_address_data[1].address_line1.value ? parent_address_data[1].address_line1.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 1, "address_line1")}
                                                                            readOnly={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[1].address_line1.valid && parent_address_data[1].address_line1.onBlur_out ? <div className="error field-error">{parent_address_data[1].address_line1.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].address_line2.label}</label>
                                                                        <input
                                                                            type={parent_address_data[1].address_line2.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[1].address_line2.placeholder}
                                                                            value={parent_address_data[1].address_line2.value ? parent_address_data[1].address_line2.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 1, "address_line2")}
                                                                            readOnly={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[1].address_line2.valid && parent_address_data[1].address_line2.onBlur_out ? <div className="error field-error">{parent_address_data[1].address_line2.error_msg}</div> : ""}
                                                                    </div>

                                                                    {/* ///////////////////////////// Mother country , state , city , postal code ,////////////////////////////////////// */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].parent_country.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={country_id.options}
                                                                            value={country_id.options.find((option) => option.label === parent_address_data[1].parent_country.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 1, "parent_country")}
                                                                        />
                                                                        {!parent_address_data[1].parent_country.valid && parent_address_data[1].parent_country.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_country.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].parent_state.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={parent_address_data[1].parent_state.options}
                                                                            value={parent_address_data[1].parent_state.options.find((option) => option.label === parent_address_data[1].parent_state.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 1, "parent_state")}
                                                                        />
                                                                        {!parent_address_data[1].parent_city.valid && parent_address_data[1].parent_city.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_city.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-2  col-xl fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].parent_city.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={parent_address_data[1].parent_city.options}
                                                                            value={parent_address_data[1].parent_city.options.find((option) => option.label === parent_address_data[1].parent_city.value)}
                                                                            onChange={(newValue) => this.selectParentCountry(newValue, 1, "parent_city")}
                                                                        />
                                                                        {!parent_address_data[1].parent_city.valid && parent_address_data[1].parent_city.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_city.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-2  col-xl fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{parent_address_data[1].parent_postcode.label}</label>
                                                                        <input
                                                                            type={parent_address_data[1].parent_postcode.type}
                                                                            className="form-control"
                                                                            placeholder={parent_address_data[1].parent_postcode.placeholder}
                                                                            value={parent_address_data[1].parent_postcode.value ? parent_address_data[1].parent_postcode.value : ""}
                                                                            onChange={(event) => this.parentAddressHandler(event, 1, "parent_postcode")}
                                                                            readOnly={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!parent_address_data[1].parent_postcode.valid && parent_address_data[1].parent_postcode.onBlur_out ? <div className="error field-error">{parent_address_data[1].parent_postcode.error_msg}</div> : ""}
                                                                    </div>


                                                                    {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.mother_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[1].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[1].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.mother_phone.value ? add_student_form.mother_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "mother_phone")}
                                                                            disabled={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.mother_phone.valid && add_student_form.mother_phone.onBlur_out ? <div className="error field-error">{add_student_form.mother_phone.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.mother_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[1].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[1].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.mother_home_phone.value ? add_student_form.mother_home_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "mother_home_phone")}
                                                                            disabled={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.mother_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={parent_address_data[1].parent_country.country_code === "" ? add_student_form.country_code.value : parent_address_data[1].parent_country.country_code.toLocaleLowerCase()}
                                                                            value={add_student_form.mother_work_phone.value ? add_student_form.mother_work_phone.value : ""}
                                                                            onChange={(event) => this.phoneNumberHandler(event, "mother_work_phone")}
                                                                            disabled={mother_details === true ? (add_student_form.data_status.value === "null" ? false : true) : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>




                                                                    {/* <div className="col-lg-12 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 py-5">{add_student_form.salutation.label}</label>
                                                                        <input
                                                                            type={add_student_form.salutation.type}
                                                                            className="form-control"
                                                                            placeholder={add_student_form.salutation.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "salutation")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>









                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Host Details</h2>
                                                                <label className="fw-bold d-flex align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input me-4"
                                                                        value={host_data.is_host_approved.value}
                                                                        defaultChecked={host_data.is_host_approved.value}
                                                                        onChange={(event) => this.inputChangehostCheckbox(event)}
                                                                        autoComplete="off"
                                                                    />
                                                                    {host_data.is_host_approved.label}
                                                                </label>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.host_name.label}</label>
                                                                        <input
                                                                            type={host_data.host_name.type}
                                                                            className="form-control"
                                                                            placeholder={host_data.host_name.placeholder}
                                                                            value={host_data.host_name.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "host_name")}
                                                                            readOnly={host_data.data_status.value === "null" ? false : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!host_data.host_name.valid && host_data.host_name.onBlur_out ? <div className="error field-error">{host_data.host_name.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.host_relation.label}</label>
                                                                        <input
                                                                            type={host_data.host_relation.type}
                                                                            className="form-control"
                                                                            placeholder={host_data.host_relation.placeholder}
                                                                            value={host_data.host_relation.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "host_relation")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!host_data.host_relation.valid && host_data.host_relation.onBlur_out ? <div className="error field-error">{host_data.host_relation.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.host_email.label}</label>
                                                                        <input
                                                                            type={host_data.host_email.type}
                                                                            className="form-control"
                                                                            placeholder={host_data.host_email.placeholder}
                                                                            onChange={(event) => this.inputChangeHost(event, "host_email")}
                                                                            value={host_data.host_email.value}
                                                                            readOnly={host_data.data_status.value === "null" ? false : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!host_data.host_email.valid && host_data.host_email.onBlur_out ? <div className="error field-error">{host_data.host_email.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.host_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={add_student_form.country_code.value ? add_student_form.country_code.value : ""}
                                                                            value={host_data.host_contact.value ? host_data.host_contact.value : ""}
                                                                            onChange={(event) => this.inputChangeHost(event, "host_contact")}
                                                                            disabled={host_data.data_status.value === "null" ? false : true}
                                                                            enableLongNumbers={true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!host_data.host_contact.valid && host_data.host_contact.onBlur_out ? <div className="error field-error">{host_data.host_contact.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.host_address.label}</label>
                                                                        <input
                                                                            type={host_data.host_address.type}
                                                                            className="form-control"
                                                                            placeholder={host_data.host_address.placeholder}
                                                                            value={host_data.host_address.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "host_address")}
                                                                            readOnly={host_data.data_status.value === "null" ? false : true}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!host_data.host_address.valid && host_data.host_address.onBlur_out ? <div className="error field-error">{host_data.host_address.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.first_point_contact.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={add_student_form.first_point_contact.options}
                                                                            value={add_student_form.first_point_contact.options.filter(function (option) {
                                                                                return option.value === add_student_form.first_point_contact.value;
                                                                            })}
                                                                            onChange={(newValue) => this.selectHandleChange(newValue, "first_point_contact", "weekend_day from value")}
                                                                            autoComplete="off"
                                                                        />
                                                                        {!add_student_form.first_point_contact.valid && add_student_form.first_point_contact.onBlur_out ? <div className="error field-error">{add_student_form.first_point_contact.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{add_student_form.first_point_email.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={add_student_form.first_point_email.options}
                                                                            value={add_student_form.first_point_email.options.filter(function (option) {
                                                                                return option.value === add_student_form.first_point_email.value;
                                                                            })}
                                                                            onChange={(newValue) => this.selectHandleChange(newValue, "first_point_email", "weekend_day")}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        {!add_student_form.first_point_email.valid && add_student_form.first_point_email.onBlur_out ? <div className="error field-error">{add_student_form.first_point_email.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <h6 className="mb-0">Remarks</h6>
                                                                        <hr />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.remark_parents.label}</label>
                                                                        <textarea
                                                                            type={host_data.remark_parents.type}
                                                                            className="form-control h-100px"
                                                                            placeholder="Parent's remarks"
                                                                            value={host_data.remark_parents.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "remark_parents")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.remark_boarding.label}</label>
                                                                        <textarea
                                                                            type={host_data.remark_boarding.type}
                                                                            className="form-control h-100px"
                                                                            placeholder="Boarding's remarks"
                                                                            value={host_data.remark_boarding.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "remark_boarding")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{host_data.remark_host.label}</label>
                                                                        <textarea
                                                                            type={host_data.remark_host.type}
                                                                            className="form-control h-100px"
                                                                            placeholder={host_data.remark_host.placeholder}
                                                                            value={host_data.remark_host.value}
                                                                            onChange={(event) => this.inputChangeHost(event, "remark_host")}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>










                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Permission’s</h2>
                                                            </div>
                                                            <div className="card-body add-new-schools p-6">
                                                                <div className="row permission-check-wrap">
                                                                    <div className="col-lg-3 fv-row">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="per"
                                                                                name="permission"
                                                                                className="form-check-input cursor-pointer"
                                                                                value="true"
                                                                                onChange={(event) => this.inputChangeHandler(event, "display_image_required")}
                                                                                autoComplete="off"
                                                                            />
                                                                            Want to display Image
                                                                        </label>
                                                                        {!add_student_form.display_image_required.valid && add_student_form.display_image_required.onBlur_out ? <div className="error field-error">{add_student_form.display_image_required.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={add_student_form.show_parent_detail_app.value}
                                                                                defaultChecked={add_student_form.show_parent_detail_app.value}
                                                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "show_parent_detail_app")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {add_student_form.show_parent_detail_app.label}
                                                                        </label>
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={add_student_form.withdraw_leave.value}
                                                                                defaultChecked={add_student_form.withdraw_leave.value}
                                                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "withdraw_leave")}
                                                                            />
                                                                            {add_student_form.withdraw_leave.label}
                                                                        </label>
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={add_student_form.permitted_student_app_signout.value}
                                                                                defaultChecked={add_student_form.permitted_student_app_signout.value}
                                                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "permitted_student_app_signout")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {add_student_form.permitted_student_app_signout.label}
                                                                        </label>
                                                                    </div>
                                                                </div>


                                                                <div className="row mt-4 permission-check-wrap">
                                                                    <div className="col-lg-3">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={this.state.father_as_host.value}
                                                                                onChange={(event) => this.inputParentCheckbox(event, "father_as_host")}
                                                                                autoComplete="off"
                                                                            />
                                                                            Exclude Father As Host
                                                                        </label>
                                                                    </div>

                                                                    <div className="col-lg-3">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={this.state.mother_as_host.value}
                                                                                onChange={(event) => this.inputParentCheckbox(event, "mother_as_host")}
                                                                                autoComplete="off"
                                                                            />
                                                                            Exclude Mother As Host
                                                                        </label>
                                                                    </div>

                                                                    <div className="col-lg-6">
                                                                        <label className="fw-bold d-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                value={add_student_form.allergy_unauth_access.value}
                                                                                defaultChecked={add_student_form.allergy_unauth_access.value}
                                                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "allergy_unauth_access")}
                                                                                autoComplete="off"
                                                                            />
                                                                            {add_student_form.allergy_unauth_access.label}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>






                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title  m-0">Allergy Information</h2>
                                                            </div>
                                                            <div className="card-body add-new-schools p-6">
                                                                <label className="fw-bold mb-2">Allergic * </label>
                                                                <div className="d-flex">
                                                                    <label className="cursor-pointer">
                                                                        <input
                                                                            type="radio"
                                                                            id="Allergy"
                                                                            name="Allergic"
                                                                            className="form-check-input me-2 cursor-pointer"
                                                                            value="yes"
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_allergy_status")}
                                                                            autoComplete="off"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                    <label className="ms-8 cursor-pointer">
                                                                        <input
                                                                            type="radio"
                                                                            id="Allergy1"
                                                                            name="Allergic"
                                                                            className="form-check-input me-2 cursor-pointer"
                                                                            value="no"
                                                                            defaultChecked
                                                                            onChange={(event) => this.inputChangeHandler(event, "student_allergy_status")}
                                                                            autoComplete="off"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            {this.state.allergy_data.map((index, i) => {
                                                                return (
                                                                    add_student_form.student_allergy_status.value === "yes" ?

                                                                        <div className="col-lg-12 fv-row mb-5" key={index.id}>
                                                                            {/* <label className="fw-bold mb-2">Allergic *</label> */}
                                                                            <div className="row"  >
                                                                                <div className="col-sm-3">
                                                                                    <input
                                                                                        // type={add_student_form.allergy_data[0]?.allergy_name.type}
                                                                                        value={index.name}
                                                                                        name="fname"
                                                                                        className="form-control"
                                                                                        placeholder="Allergy Name"
                                                                                        onChange={(event) => this.inputChangeAllergyName(event, index.id, i)}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        // type={add_student_form.allergy_data[0]?.allergy_info.type}
                                                                                        name="fname"
                                                                                        value={index.info}
                                                                                        className="form-control"
                                                                                        placeholder="More Info"
                                                                                        onChange={(event) => this.inputChangeAllergyInfo(event, index.id, i)}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-1">
                                                                                    <div className="d-flex justify-content-around flex-shrink-0">
                                                                                        <span
                                                                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                            data-bs-toggle="tooltip"
                                                                                            title="Add"
                                                                                            data-bs-original-title="Add"
                                                                                            aria-describedby="tooltip230873"

                                                                                        >
                                                                                            <span className="svg-icon svg-icon-3 pointer" onClick={(i) => this.clickAddAllergyrow(index.id)}>
                                                                                                <i className="fas fa-plus"></i>
                                                                                            </span>
                                                                                        </span>

                                                                                        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                                            data-bs-toggle="tooltip" title="Delete" data-bs-original-title="Delete">
                                                                                            <span className="svg-icon svg-icon-3" key={index.id} onClick={(e) => this.clickDeleteAllergyrow(index, e)}>
                                                                                                <i className="far fa-trash-alt"></i>
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        :
                                                                        null
                                                                )
                                                            })
                                                            }
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Add Student
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,

    onGetParentDetailsByFatherEmail: userActions.GetParentDetailsByFatherEmail,
    onGetParentDetailsByMotherEmail: userActions.GetParentDetailsByMotherEmail,

    onGetStudentHostByHostEmail: userActions.GetStudentHostByHostEmail,
    onGetStudentHostByHostContact: userActions.GetStudentHostByHostContact,

    onCreateStudentAndParents: userActions.CreateStudentAndParents,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,



    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);