import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Pagination from '@mui/material/Pagination';

import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';

const HostReport = (props) => {
    const [hostList, setHostList] = useState([])
    const [hostsApprovedBy, setHostApprovedBy] = useState("")
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const [is_host_approved, setIs_host_approved] = useState("")
    const [pdf_url,setPdf_url] = useState("")
    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 },
    ]

    
    useEffect(() => {
        BssHostReport()
        hostApproved()
    }, [page, limit, is_host_approved])

    const BssHostReport = () => {
        props.onBssHostReport(page, limit, is_host_approved).then((response) => {
            if (response.success === true) {
                setHostList(response.data)
                setCount(response.totalCount)
                setPdf_url(response.file)
            } else {
            }
        });

    }

    const hostApproved = () => {
        const local_stoage_data = JSON.parse(window.localStorage.getItem('bss_authuser'));
        setHostApprovedBy(local_stoage_data.first_name + " " + local_stoage_data.last_name)
    }

    const handleChange = (event, value) => {
        setPage(value);
    };

    const changeLimit = (e) => {
        setLimit(parseInt(e.value));
    };

    const DownloadPdf = () => {
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }



    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="card  tabs-outer">
                                                            <div className="card-header">
                                                                <h2 className="card-title">All Hosts</h2>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="m-0 me-3">Download PDF</p>
                                                                    <IconButton onClick={()=>DownloadPdf()}>
                                                                        <i class="fas fa-file-pdf"></i>
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="card-toolbar">
                                                                    {hostList.map((host) => {
                                                                        return (
                                                                            <div>
                                                                                <div className="card-1 p-6 mb-4 rounded bg-light-info">
                                                                                    <div className="row">
                                                                                        <div className="col-9">
                                                                                            <div className="d-flex mb-3">
                                                                                                <h3 className="mb-0 text-primary superadmin-headings">{host.host_name}</h3>
                                                                                                <div className="d-flex ms-6 align-items-center">
                                                                                                    {host.stu_host.host_status ? (
                                                                                                        <span className="badge badge-light-success px-4 py-2">Active</span>
                                                                                                    ) : (
                                                                                                        <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex text-secondary align-items-center mb-3 flex-wrap">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <Tooltip title="Host email address" placement="top">
                                                                                                        <i className="bi bi-envelope me-2 fs-6"></i>
                                                                                                    </Tooltip>
                                                                                                    <p className="mb-0 heding-element me-4">{host.host_email}</p>
                                                                                                </div>

                                                                                                <div className="d-flex align-items-center">

                                                                                                    <Tooltip title="Host Contact" placement="top">
                                                                                                        <i className="fas fa-mobile-alt me-2 fs-6"></i>
                                                                                                    </Tooltip>
                                                                                                    <p className="mb-0 heding-element me-4">+ {host.host_contact}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex text-secondary align-items-center mb-3 flex-wrap">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <Tooltip title="Host Address" placement="top">
                                                                                                        <i className="fas fa-address-card fs-6 me-2"></i>
                                                                                                    </Tooltip>
                                                                                                    <p className="mb-0 heding-element">{host.host_address}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-3 text-right">
                                                                                            {host.stu_host.is_host_approved === true ?
                                                                                                <div className="d-flex flex-column align-items-center">
                                                                                                    <img src="/assets/media/host-approved.png" class="img-fluid" style={{ width: "100px" }}></img>
                                                                                                    <p>Approved By - {hostsApprovedBy}</p>
                                                                                                </div>
                                                                                                :
                                                                                                <h3>Not Approved</h3>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className="card-footer">
                                                                {console.log("hostList", Math.ceil(hostList.length / limit))}
                                                                <div className="d-flex justify-content-between">
                                                                    <Select
                                                                        menuPlacement="top"
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        defaultValue={row_limit[1]}
                                                                        name="color"
                                                                        options={row_limit}
                                                                        onChange={changeLimit}
                                                                    />
                                                                    <Stack spacing={1} >
                                                                        <Pagination
                                                                            count={Math.ceil(count / limit)}
                                                                            page={page}
                                                                            onChange={handleChange}
                                                                            color="primary"
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h2 className="card-title m-0">Filters</h2>
                                                            </div>
                                                            <div className="card-body p-6">
                                                                <div className="d-flex flex-column mt-6">
                                                                    <h5>Show</h5>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_host_approved === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => setIs_host_approved("")}
                                                                        >All</span>
                                                                        <span className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_host_approved === "true" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => setIs_host_approved("true")}
                                                                        >Approved Host</span>
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_host_approved === "false" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => setIs_host_approved("false")}

                                                                        >Unapproved Host</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onBssHostReport: userActions.BssHostReport
};
export default connect(mapStateToProps, mapDispatchToProps)(HostReport);