import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddStudent from "../component/Student/Student list/AddStudent";
import AllergicStudent from "../component/Student/AllergicStudent/AllergicStudent";
import DuplicateStudentList from "../component/Student/DuplicateStudent/DuplicateStudentList";
import GenericList from "../component/Student/GenericStudent/GenericList";
import GroundedList from "../component/Student/GroundedStudent/GroundedList";
import ImportStudent from "../component/Student/ImportStudent/ImportStudent";

import StudentList from "../component/Student/Student list/StudentList";
import EditStudent from "../component/Student/Student list/EditStudent";
import AddGroundedStudent from "../component/Student/GroundedStudent/AddGroundedStudent";
import AddGenericStudent from "../component/Student/GenericStudent/AddGenericStudent";
import AddAllergicStudent from "../component/Student/AllergicStudent/AddAllergicStudent";
import EditGroundedStudent from "../component/Student/GroundedStudent/EditGroundedStudent";
import EditGenericStudent from "../component/Student/GenericStudent/EditGenericStudent";
import EditAllergicStudent from "../component/Student/AllergicStudent/EditAllergicStudent";
import StudentProfile from "../component/Student/Student list/Student Profile/StudentProfile";
import ApprovedHost from "../component/Student/Student list/Student Profile/ApprovedHost";
import LeaveHistory from "../component/Student/Student list/Student Profile/LeaveHistory";
import AttendanceHistory from "../component/Student/Student list/Student Profile/AttendanceHistory";
import AllergyReports from "../component/Student/Student list/Student Profile/AllergyReports";
import MedicalNotes from "../component/Student/Student list/Student Profile/MedicalNotes";
import PastoralNotes from "../component/Student/Student list/Student Profile/PastoralNotes";
import IDCard from "../component/Student/Student list/Id Card/IDCard";

import AdhocLeave from "../component/Student/Student list/Student Profile/AdhocLeave";
import * as userActions from "../actions/index";
import { connect, useDispatch } from "react-redux";
import AdhocActual from "../component/Student/Student list/Student Profile/AdhocActual";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const Student = (props) => {
    const dispatch = useDispatch();
    let userId = useParams();
    const [studentData, setStudentData] = useState()
    const GetStudentAndParentById = useCallback(async () => {
        props.onGetStudentAndParentById(userId.id).then((response) => {
            if (response.success === true) {
                let data = response.data
                setStudentData(data)
            } 
        })
    }, [props.onGetStudentAndParentById, userId.id])

    useEffect(() => {
        GetStudentAndParentById();
    }, [GetStudentAndParentById])

    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();
        console.log("location====1",location);
        console.log("location=====2",userId);
        if (location.pathname === `/${school_code}/student/allergic-student` || location.pathname === `/${school_code}/student/allergic-student/`) {
            return <AllergicStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/flag-student` || location.pathname === `/${school_code}/student/flag-student/`) {
            return <GenericList /> ;
        }

        if (location.pathname === `/${school_code}/student/grounded-student` || location.pathname === `/${school_code}/student/grounded-student/`) {
            return <GroundedList /> ;
        }
        if (location.pathname === `/${school_code}/student/import-student` || location.pathname === `/${school_code}/student/import-student/`) {
            return <ImportStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/duplicate-student` || location.pathname === `/${school_code}/student/duplicate-student/`) {
            return <DuplicateStudentList /> ;
        }
        
        if (location.pathname === `/${school_code}/student/student-list` || location.pathname === `/${school_code}/student/student-list/`) {
            return <StudentList /> ;
        }
        if (location.pathname === `/${school_code}/student/add-student` || location.pathname === `/${school_code}/student/add-student/`) {
            return <AddStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/edit-student/${userId.id}` || location.pathname === `/${school_code}/student/student-list/edit-student/${userId.id}/`) {
            return <EditStudent user_uuid={userId.id} /> ;
        }
        if (location.pathname === `/${school_code}/student/grounded-student/add-grounded-student` || location.pathname === `/${school_code}/student/grounded-student/add-grounded-student/`) {
            return <AddGroundedStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/flag-student/add-generic-student` || location.pathname === `/${school_code}/student/flag-student/add-generic-student/`) {
            return <AddGenericStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/allergic-student/add-allergic-student` || location.pathname === `/${school_code}/student/allergic-student/add-allergic-student/`) {
            return <AddAllergicStudent /> ;
        }
        if (location.pathname === `/${school_code}/student/allergic-student/edit-allergic-student` || location.pathname === `/${school_code}/student/allergic-student/add-allergic-student/`) {
            return <EditStudent  /> ;
        }
        if (location.pathname === `/${school_code}/student/grounded-student/edit-grounded-student/${userId.id}` || location.pathname === `/${school_code}/student/grounded-student/edit-grounded-student/${userId.id}/`) {
            return <EditGroundedStudent user_uuid={userId.id} /> ;
        }
        if (location.pathname === `/${school_code}/student/flag-student/edit-generic-student/${userId.id}` || location.pathname === `/${school_code}/student/flag-student/edit-generic-student/${userId.id}/`) {
            return <EditGenericStudent user_uuid={userId.id}  /> ;
        }
        if (location.pathname === `/${school_code}/student/allergic-student/edit-allergic-student/${userId.id}` || location.pathname === `/${school_code}/student/allergic-student/edit-allergic-student/`) {
            return <EditAllergicStudent user_uuid={userId.id} /> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/student-profile/${userId.id}` || location.pathname === `/${school_code}/student/student-list/student-profile/${userId.id}/`) {
            return <StudentProfile user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/approved-host/${userId.id}` || location.pathname === `/${school_code}/student/student-list/approved-host/${userId.id}/`) {
            return <ApprovedHost user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/leave-history/${userId.id}` || location.pathname === `/${school_code}/student/student-list/leave-history/${userId.id}/`) {
            return <LeaveHistory user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/attendance-history/${userId.id}` || location.pathname === `/${school_code}/student/student-list/attendance-history/${userId.id}/`) {
            return <AttendanceHistory user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/pastoral-notes/${userId.id}` || location.pathname === `/${school_code}/student/student-list/pastoral-notes/${userId.id}/`) {
            return <PastoralNotes user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/medical-notes/${userId.id}` || location.pathname === `/${school_code}/student/student-list/medical-notes/${userId.id}/`) {
            return <MedicalNotes user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/allergy-reports/${userId.id}` || location.pathname === `/${school_code}/student/student-list/allergy-reports/${userId.id}/`) {
            return <AllergyReports user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/adhoc-leave/${userId.id}` || location.pathname === `/${school_code}/student/student-list/adhoc-leave/${userId.id}/`) {
            return <AdhocLeave user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/adhoc-actual/${userId.id}` || location.pathname === `/${school_code}/student/student-list/adhoc-actual/${userId.id}/`) {
            return <AdhocActual user_uuid={userId.id} studentData={studentData}/> ;
        }
        if (location.pathname === `/${school_code}/student/student-list/temp-id` || location.pathname === `/${school_code}/student/student-list/temp-id/`) {
            return <IDCard /> ;
        }
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetStudentAndParentById: userActions.GetStudentAndParentById,
    // onGetStudentDiaryCommentsById: userActions.GetStudentDiaryCommentsById,
    // onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,
    // onCreateDiaryPastoralComments: userActions.CreateDiaryPastoralComments,
};
export default connect(mapStateToProps, mapDispatchToProps)(Student);