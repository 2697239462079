import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../Constant/sidebar";
import TopBar from "../Constant/TopBar";
import Footer from "../Constant/Footer";
import { Link, json } from "react-router-dom";
import * as userActions from "../../actions/index";
import parse from 'html-react-parser';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import * as actionType from "../../constants/actionTypes";
import Select from 'react-select';
import moment from "moment";
import "moment-timezone";
import { connect, useDispatch } from "react-redux";







const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;
const firstIndex = 0;
const Activities = (props) => {
    const dispatch = useDispatch();
    const [logData, setLogsData] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState();
    const [order, setOrder] = useState();
    const [count, setCount] = useState();
    const [search, setSearch] = useState("");

    const [studentList, setStudentList] = useState([]);
    const [avatar, setAvatar] = useState({ user_id: '', thumbnail_image: "/assets/media/school-logo.png" })
    const [mangerAvatar, setManagerAvatar] = useState([])
    const [avtarId, setAvatarId] = useState([])

    const row_limit = [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 250, label: 250 },
        { value: 500, label: 500 }
    ]

    useEffect(() => {

        if (permission_info?.view_user === false) {
            // if(permission_info?.view_user === false){
            let Row = document.querySelectorAll(".manager")
            for (let i of Row) {
                i.removeAttribute("href");
                i.href = `/${school_code}/no-permission`;
            }
            // }
        }
    }, [logData])

    const GetSystemLogs = async () => {
        props.onGetSystemLogs(page, limit, sort, order, search).then((response) => {
            setCount(response.data.count)
            if (response.success === true) {
                let filterLogArray = []
                const Alldata = response.data.rows;
                console.log("Alldata", mangerAvatar);
                for (let key in Alldata) {
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const timezone = user_info?.user_school?.timezone;
                    const date = moment(Alldata[key].created_date)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');
                    let arr = mangerAvatar.find((avatarItem) => {
                        return avatarItem.user_id === Alldata[key].user_id
                    })
                    let newSentence = "Snapshot" === Alldata[key].html_info.slice(-13, -5) ? Alldata[key].html_info.replace(`href="/snapshot"`, `href="/${school_code}/snapshot"`) : Alldata[key].html_info;
                    filterLogArray.push({
                        ...Alldata[key],
                        html_info: newSentence,
                        created_date: date,
                        thumbnail_image: arr === undefined ? "" : arr.thumbnail_image
                    })
                }
                setLogsData(filterLogArray);
            }
        }).catch(() => { })
    }


    //======================== for manager profile



    const GetAllManagersBySchoolId = async () => {
        const bss_authuser = JSON.parse(localStorage.getItem("bss_authuser"))
        props.onGetAllManagersBySchoolId().then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                const avatarArray = [
                    {
                        user_id: bss_authuser.user_id,
                        thumbnail_image: actionType.environment_url + '/' + bss_authuser.avatar,
                    }
                ];
                avatarArray.push(avatar)
                const Avatar_id = []
                for (let key in arrayData) {
                    avatarArray.push({
                        user_id: arrayData[key].user_id,
                        thumbnail_image: actionType.environment_url + '/' + arrayData[key].avatar,
                    })
                    Avatar_id.push(arrayData[key].user_id)
                }
                setAvatarId(Avatar_id)
                setManagerAvatar(avatarArray)
            } else {
            }
        });
    };



    const GetSchoolById = () => {
        const user_data = JSON.parse(localStorage.getItem("bss_authuser"))
        const user_uuid = user_data.user_school.school_detail_uuid
        props.onGetSchoolById(user_uuid).then((response) => {
            if (response.success === true) {
                let school_data = response.data;
                const boarding_head_avatar = school_data["admin_user"][0].avatar;
                if (boarding_head_avatar.avatar !== null && boarding_head_avatar.avatar !== '') {
                    setAvatar({
                        user_id: school_data["admin_user"][0].user_id,
                        thumbnail_image: actionType.environment_url + '/' + boarding_head_avatar,
                    })
                }

            } else {
            }
        });
    }

    useEffect(() => {
        GetSchoolById()
    }, [])
    useEffect(() => {
        GetAllManagersBySchoolId()
    }, [avatar])

    useEffect(() => {
        GetSystemLogs(page, limit, sort, order, search);
    }, [page, limit, sort, order, search, mangerAvatar])


    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card tabs-outer">
                                                                <div className="card-header position-relative py-0 details-bg">
                                                                    <h2 className="card-title  m-0">
                                                                        Activities
                                                                    </h2>
                                                                </div>
                                                                <div className="card-body position-relative">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="d-flex justify-content-between align-items-center pb-5 px-2 border-bottom border-1 border-secondary">
                                                                                <div className="card-toolbar">
                                                                                    <div className="d-flex align-items-center justify-content-between export-out">
                                                                                        <div className="d-flex align-items-center position-relative">
                                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                                    <path
                                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                                        fill="currentColor"
                                                                                                    ></path>
                                                                                                </svg>
                                                                                            </span>
                                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }} onChange={(e) => setSearch(e.target.value)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <span className="text-secondary"><strong>Date & Time</strong></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            {logData.map((item) => {
                                                                                {
                                                                                    console.log('====================================');
                                                                                    console.log("item=======", item);
                                                                                    console.log('====================================');
                                                                                }
                                                                                return (
                                                                                    <div className="row py-3 px-2 border-bottom">
                                                                                        <div className="col-9">
                                                                                            <div className="d-flex justify-content-start align-items-center">
                                                                                                <div className="me-3">
                                                                                                    <img
                                                                                                        alt="/assets/media/school-logo.png"
                                                                                                        src={item.thumbnail_image === "" || item.thumbnail_image === "https://bssnew.dikonia.in/" ? "/assets/media/school-logo.png" : item.thumbnail_image}
                                                                                                        className="rounded-circle" style={{ width: "30px" }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {parse(item.html_info)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-3 text-end">
                                                                                            <div className="d-flex justify-content-end align-items-center">
                                                                                                <span className="bg-light-info px-2 py-1 rounded-3 text-secondary">{item.created_date}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex justify-content-between mt-4">
                                                                        <Select
                                                                            menuPlacement="top"
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            defaultValue={row_limit[0]}
                                                                            name="color"
                                                                            options={row_limit}
                                                                            onChange={(event) => setLimit(event.value)}
                                                                        />
                                                                        <Stack spacing={1} >
                                                                            <Pagination
                                                                                count={Math.ceil(count / limit)}
                                                                                page={page}
                                                                                onChange={(event, value) => setPage(value)}
                                                                                color="primary"
                                                                            />
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetSystemLogs: userActions.GetSystemLogs,
    onGetAllStudents: userActions.GetAllStudents,
    onGetSchoolById: userActions.GetSchoolById,
    onGetAllManagersBySchoolId: userActions.GetAllManagersBySchoolId
};
export default connect(mapStateToProps, mapDispatchToProps)(Activities);