import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import Footer from "../../Constant/Footer";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import { toast } from "react-toastify";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import * as userActions from "../../../actions/index";

import MessageItem from "./MessageItem";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const MessageList = (props) => {
    const dispatch = useDispatch();

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder] = useState("asc");
    const [filtersort] = useState("user_message_id");


    const [MessageList, setMessageList] = useState([]);
    const [MessageListcount, setMessageListcount] = useState(0);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);
    const [confirm_modal, setconfirm_modal] = useState(false);

    const [filtersearch, setfiltersearch] = useState(null);

    const GetAllUserMessages = useCallback(async () => {
        dispatch(userActions.GetAllUserMessages(filterpage, filterlimit, filtersort, filterorder, filtersearch)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;

                //    console.log("=======",response.data.rows[0].user_message_uuid);
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        user_message_title: arrayData[key].user_message_title,
                        user_message_desc: arrayData[key].user_message_desc,
                        is_user_message_active: arrayData[key].is_user_message_active,
                        user_message_uuid: arrayData[key].user_message_uuid,
                    });
                }
                setMessageList(MemberId_level);
                setMessageListcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    useEffect(() => {
        GetAllUserMessages();
    }, [GetAllUserMessages, filterpage, filterlimit, filtersort, filterorder, filtersearch]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };

    const RemoveCourseModule = (event, course_data) => {
        console.log("course_data", course_data);
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log('form_data===', form_data)
        setconfirm_modal(false);
        props.onDeleteUserMessage(form_data).then((response) => {
            if (response.success === true) {
                GetAllUserMessages();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                // setadd_course_modal(false);
                // setedit_course_modal(false);
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };


    const handleChange = async (event, user_message_uuid, value) => {
        const Payload = {
            user_message_uuid: user_message_uuid,
            is_user_message_active: value,
        };
        props.onUpdateUserMessageStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllUserMessages();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    return (
        <>

            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Message?`}
                    bodytitle="Are you sure you want to delete this Message?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            {/* <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar /> */}
                            {/* <div className="content d-flex flex-column flex-column-fluid heding-element" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post"> */}
                                    {/* <div id="kt_content_container" className="container-fluid"> */}
                                        <div className="row gx-5 gx-xl-10 mt-10">
                                            <div className="col-xl-12">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab">
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                {/* <!--begin::Card title--> */}
                                                                <h3 className="card-title  m-0">Responsibilities of Student

                                                                    <Tooltip title="These messages can appear when students are applying for their leave.  Students will acknowledge the message before their leave is approved.This message will not display when staff (managers) are applying for leave on the students behalf – using desktop computers." placement="top">
                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                    </Tooltip>

                                                                </h3>

                                                                <div className="card-toolbar">
                                                                    <Link to={`/${school_code}/settings/messages/add-message`} className="btn btn-sm btn-primary apply-btn add-btns">Add Message</Link>
                                                                </div>

                                                            </div>

                                                            <div className="d-flex align-items-center position-relative p-6">
                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                        <path
                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </svg>
                                                                </span>

                                                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                    onChange={(event) => {
                                                                        setfiltersearch(event.target.value);
                                                                        setfilterpage(1);
                                                                    }}

                                                                />
                                                            </div>


                                                            {/* <!-- <h4 className="card-title  m-0">Schools</h4> --> */}


                                                            <div className="row">
                                                                <div className="col-sm-12">

                                                                    {<MessageItem

                                                                        MessageListcount={MessageListcount}
                                                                        MessageList={MessageList}
                                                                        data={MessageList}
                                                                        propsfilterpage={(event) => setfilterpage(event)}
                                                                        RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                                        handleChange={(event, user_message_uuid, value) => handleChange(event, user_message_uuid, value)}
                                                                        filterlimit={filterlimit}
                                                                        filterpage={filterpage}
                                                                        propsfilterlimit={(filterlimit) => {
                                                                            setfilterlimit(filterlimit);
                                                                        }}
                                                                    />}




                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <!--end::Container--> */}
                        {/* </div>
                        </div> */}
                <Footer></Footer>
            {/* // </div> */}


        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onLoginAsSchoolAdmin: userActions.LoginAsSchoolAdmin,
    onDeactivateSchool: userActions.DeactivateSchool,
    onDeleteUserMessage: userActions.DeleteUserMessage,
    onUpdateUserMessageStatus: userActions.UpdateUserMessageStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageList);