import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../../actions/index";
import * as actionType from "../../../../constants/actionTypes";
import Highlighter from "react-highlight-words";

import moment from "moment";
import "moment-timezone";



const MedicalNotes = (props) => {
    const [medical_data, setmedical_data] = useState([])
    const [medical_Array, setmedical_Array] = useState([])
    const [student_name, setStudent_name] = useState({})
    const [dormitory_data, setdormitory_data] = useState({})
    const [parent_data, setparent_data] = useState({})
    const [nameList, setNameList] = useState([])
    const [searchComment, setsearchComment] = useState("")
    const [highLighter, sethighLighter] = useState([])
    const [student_id, setstudent_id] = useState(0)
    const [student_avatar, setStudent_avatar] = useState({ value: "", thumbnail_image: "/assets/media/school-logo.png" })
    const dispatch = useDispatch();

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;
    const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;

    useEffect(() => {
        GetStudentAndParentById();
    }, [])

    const GetStudentAndParentById = useCallback(async () => {
        props.onGetStudentAndParentById(props.user_uuid).then((response) => {
            console.log("response", response);
            if (response.success === true) {
                const student_all_data = response.data;
                for (let key in student_all_data) {
                    setstudent_id(student_all_data["student_id"])
                    if (student_all_data.student_avatar !== null && student_all_data.student_avatar !== '') {
                        setStudent_avatar({
                            value: student_all_data["student_avatar"],
                            thumbnail_image: actionType.environment_url + '/' + student_all_data["student_avatar"]
                        });
                    }
                    setStudent_name({
                        student_first_name: student_all_data["student_first_name"],
                        student_last_name: student_all_data["student_last_name"],
                        date_of_birth: student_all_data["date_of_birth"]
                    })
                    if (key === "dormitory_data" && student_all_data[key] !== null) {
                        setdormitory_data({
                            dormitory_name: student_all_data[key].dormitory_name
                        })
                    }
                    if (key === "parent_data" && student_all_data[key] !== null) {
                        setparent_data({
                            mother_name: student_all_data[key].mother_name,
                            mother_email: student_all_data[key].mother_email,
                            mother_phone: student_all_data[key].mother_phone,
                            father_name: student_all_data[key].father_name,
                            father_email: student_all_data[key].father_email,
                            father_phone: student_all_data[key].father_phone
                        })
                    }

                }
            } else {
            }
        })
    }, [])


    useEffect(() => {
        GetStudentDiaryCommentsById();
    }, [student_id])
    const GetStudentDiaryCommentsById = useCallback(async () => {
        props.onGetStudentDiaryCommentsById(student_id, "medical").then((response) => {
            if (response.success === true) {
                setmedical_data(response.data)
                setmedical_Array(response.data)
            } else {
            }
        });
    }, [student_id]);



    const searchHandler = (e) => {
        setsearchComment(e.target.value)
        const modifyArray = medical_data.filter((item) => {
            return item.diary_comment_desc.toLowerCase().includes(e.target.value.toLowerCase())
        })
        let highLight = []
        highLight.push(e.target.value)
        sethighLighter(highLight)
        setmedical_Array(modifyArray)
    }

    const clearSearchAll = () => {
        setsearchComment("")
        GetStudentDiaryCommentsById()
    }

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                    <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                        <div style={{display:"flex"}}>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                    Student Profile
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" aria-controls="process" aria-selected="true">
                                                    Approved Hosts
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Leave History
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Attendance History
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Pastoral Notes
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link active" id="MedicalNotes-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Medical Notes
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link " id="AllergyReport-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Allergy Reports
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                    AdhocLeave
                                                </Link>
                                            </li>
                                            <li className="nav-item me-6" role="presentation">
                                                <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" aria-controls="process" aria-selected="false">
                                                    Adhoc Actual
                                                </Link>
                                            </li>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                            <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                <img alt="Logo" style={{marginBottom:"4px"}} 
                                                    src={
                                                        props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                        ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                        : (props?.studentData?.gender === "male" 
                                                            ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                            : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ul>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 d-flex align-items-center justify-content-between mb-4">
                                                                <div className="d-flex align-items-center position-relative ">
                                                                    <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                            <path
                                                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                fill="currentColor"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>

                                                                    <input type="text" onChange={(e) => searchHandler(e)} value={searchComment} className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }} />
                                                                </div>
                                                                <div className="card-toolbar pointer">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => clearSearchAll()}
                                                                        className="btn btn-sm btn-success min-150px mt-4 mb-2"
                                                                    >
                                                                        Clear Search
                                                                    </button>
                                                                    {/* <span onClick={() => clearSearchAll()}>Clear Search</span> */}
                                                                </div>
                                                            </div>
                                                            {medical_Array.map((item) => {
                                                                return (
                                                                    <div className="col-sm-12 my-2">
                                                                        <div className="d-flex align-items-center border-1 border-dashed card-rounded p-2 p-lg-4 ">
                                                                            <div className="mb-0">
                                                                                <div className=" lh-lg mb-2 ">
                                                                                    <Highlighter
                                                                                        highlightStyle={{ backgroundColor: "#ffe00096", borderRadius: "25px" }}
                                                                                        highlightClassName="YourProfileHighlightClass"
                                                                                        searchWords={[...highLighter]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={item.diary_comment_desc}
                                                                                    />
                                                                                </div>
                                                                                <div className="fw-semibold link-primary">
                                                                                    {item?.comment_by_user?.first_name + " " + item?.comment_by_user?.last_name}
                                                                                </div>
                                                                                <span className="text-gray-400 fs-7 fw-semibold d-block mt-1">{moment.utc(item?.created_date).format('MMMM Do YYYY, h:mm a')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetStudentAndParentById: userActions.GetStudentAndParentById,
    onGetStudentDiaryCommentsById: userActions.GetStudentDiaryCommentsById,
    onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,
};
export default connect(mapStateToProps, mapDispatchToProps)(MedicalNotes);
